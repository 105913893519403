import * as React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import LinkWrapper from '../../../components/LinkWrapper';

function SimpleCta(props) {
  const { text, link, color, bgColor, inHero, isButton } = props;
  const [hover, setHover] = React.useState(false);

  const commonProps = {
    w: inHero ? { base: 'max-content', ms: 'max-content' } : { base: 'max-content', ms: 'max-content' },
    justifyContent: 'center',
    alignItems: 'center',
    color: !hover ? color : bgColor,
    bgColor: !hover ? bgColor : (color === '#F1F0FA' || color === '#FFFFFF') ? 'blue' : color,
    borderRadius: { base: '32px' },
    p: inHero ?  { base: '0px 49px 0px 49px' } : { base: '0px 49px 0px 49px' },
    mx: isButton ? '': 'auto',
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
    
    border: '1px',
    height: '81px',
    borderColor: color === '#ffffff' ? bgColor : 'transparent',
  };

  const textProps = {
    fontFamily: 'Neutraface',
    fontSize: inHero ? { base: '20px', lg: '40px' } : { base: '20px', lg: '40px' },
    lineHeight: inHero ? { base: '35px', lg: '70px' } : { base: '35px', lg: '70px' },
    
    padding: isButton ? {base: '20px 0px 20px 0px', lg: '20px 0px 20px 0px'} : inHero ? { base: '2px 0px 0px 0px', lg: '5px 0px 0px 0px' } : { base: '2px 0px 0px 0px', lg: '5px 0px 0px 0px' },
  };

  return isButton ? (
    <Button
      {...commonProps}
      _hover={{
        bgColor: (color === '#F1F0FA' || color === '#FFFFFF') ? 'blue' : color,
        color: bgColor,
      }}
      {...props}
    >
      <Text {...textProps}>{text}</Text>
    </Button>
  ) : (
    <LinkWrapper href={link}>
      <Flex {...commonProps}>
        <Text {...textProps}>{text}</Text>
      </Flex>
    </LinkWrapper>
  );
}

export default SimpleCta;