import * as React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import CTAButton from '../../components/CTAButton';
import filterTypes from '../../utitlities/filterTypes';

type threeCategoriesWideHomeProps = {
  header: { headerText: string; textColor: { color: string } };
  headerWarning: { headerText: string; textColor: { color: string } };
  additionalProducts: {
    categoryName: { headerText: string; textColor: { color: string } };
    categoryDescription: { headerText: string; textColor: { color: string } };
    cta: {
      text: string;
      textColor: { color: string };
      bgColor: { color: string };
      linkToPage: { slug: { current: string } };
    };
    image: {};
  }[];
};

function ThreeCategoriesWideHome(props: threeCategoriesWideHomeProps) {
  const { header, headerWarning, additionalProducts } = props;

  return (
    <Box
      maxW={{base:'90%', md:'98%', lg:'94%', lgs:'84%', xl:'1200px'}}
      m='auto'
      mt={{base:'76px', md: '117px', lg: '114px', xl: '134px'}}
    >
      {/* TITLE CONTAINER */}
      <Box 
        mx='auto' 
        display={{ base: 'block', md: 'flex' }} 
        justifyContent={{ md: 'center' }} 
        alignItems='center'
        pb={{base:'15px', md:'15px'}}
        mb={{base:'46px', md:'62px'}}
        // border="1px"
      >
        <Text
          color={header.textColor?.color || 'primary'}
          fontSize={{base:'18px', md:'13px', lg:'20px'}}
          letterSpacing={{base:'4.5px', md:'6px'}}
          lineHeight={{base: '30px', md:'20px'}}
          fontWeight={{base:'900', md:'800'}}
          textAlign='center'
          textTransform='uppercase'
        >
          {header.headerText}
        </Text>

        <Text
          display={{base:'none', md:'block' }}
          color={header.textColor?.color || 'primary'}
          fontSize={{base:'12px', md:'13px', lg:'20px'}}
          letterSpacing={{base:'4.5px', md:'6px'}}
          lineHeight={{base: '30px', md:'20px' }}
          fontWeight={{base:'900', md:'800'}}
          textAlign='center'
          mx='20px'
        >
          |
        </Text>

        <Text
          color={headerWarning.textColor?.color || 'primary'}
          fontSize={{base:'10px', md:'13px', lg:'20px'}}
          letterSpacing={{base:'4.5px', md:'6px'}}
          lineHeight={{base:'30px', md:'20px'}}
          fontWeight={{md:'800'}}
          textAlign='center'
          textTransform='uppercase'
        >
          {headerWarning.headerText}
        </Text>
      </Box>

      {/* PRODUCTS */}
      <Flex
        w='100%'
        display='flex'
        flexDirection={{base:'column', md:'row'}}
        justifyContent={{base:'center'}}
        alignItems={{base:'center', mds:'flex-end'}}
      >
        {additionalProducts.map((category, indx) => {
          return (
            <Box key={indx} flex='1' mb={{base:'60px', md:'0'}}>
              <CategoryContainer
                categotyImg={category.image}
                categoryName={category.categoryName.headerText}
                categoryNameColor={category.categoryName.textColor?.color || 'blue'}
                categoryDescription={category.categoryDescription.headerText}
                categoryCTA={category.cta}
              />
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
}

type categoryContainerProps = {
  categotyImg: {};
  categoryName: string;
  categoryNameColor: string;
  categoryDescription: string;
  categoryCTA: {
    text: string;
    textColor: { color: string };
    bgColor: { color: string };
    linkToPage: { slug: { current: string } };
  };
};

const CategoryContainer = (props: categoryContainerProps) => {
  const { categotyImg, categoryName, categoryNameColor, categoryDescription, categoryCTA } = props;
  const img = getImage(categotyImg.asset);
  
  const getLinkTo = (linkTo, category) => {
    const linkBase = linkTo;
    const linkCategory = category.replace(/shop| /g, '').toLowerCase();
    const link = `/${linkBase}/?${filterTypes.CATEGORY}=${linkCategory}`;

    return link;
  };

  return (
    <Flex
      h="100%"
      justifyContent={{base:'center', mds:'flex-end'}}
      // bgColor="blue"
    >
      {/* IMAGE */}
      <Flex
        maxW={{base:'140px', ms:'150px', md:'150px', mds:'154px', lg:'170px', lgs:'174px', xl:'200px'}}
        h={{base:'177px', ms:'177px', md:'150px', mds:'150px', lg:'170px', lgs:'170px', xl:'207px'}}
      >
        <GatsbyImage
          // className='home-featured-categories'
          imgStyle={{objectFit:'contain', maxWidth:'100%', maxHeight:'100%'}}
          image={img}
          alt={categotyImg.asset.altText || 'category image'}
        />
      </Flex>

      {/* INFO */}
      <Flex 
        w={{base:'175px', ms:'180px', mds:'60%', lg:'55%'}} 
        flexDirection='column' 
        pl={{base:'10px', md:'0px', mds:'10px', lg:'10px'}}
        pb='20px'
      >
        {/* NAME */}
        <Box>
          <Text
            color={categoryNameColor || '#275397'}
            fontSize={{base:'26px', md:'22px', mds:'23px', lg:'27px', xl:'28px'}}
            fontWeight={{base:'900', lg:'900'}}
            letterSpacing={{base:'0.93px', lg:'1px' }}
            lineHeight={{base:'35px'}}
            pb={{base:'6px', lg:'6px'}}
          >
            {categoryName.toUpperCase()}
          </Text>
        </Box>

        {/* DESCRIPTION */}
        <Box h='100%' w={{base:'100%'}} display='flex' alignItems='flex-start'>
          <Text
            maxW={{base:'134px', lg:'180px'}}
            color={'primary'}
            fontSize={{base:'15px', lg:'18px'}}
            fontWeight='600'
            letterSpacing={{base:'0'}}
            lineHeight={{base:'25px', lg:'26px'}}
            // textTransform='capitalize'
            pb={{base:'6px', lg:'6px'}}
            // border="1px"
          >
            {categoryDescription}
          </Text>
        </Box>

        {/* BUTTON */}
        <Box 
          w='100%' 
          display='flex' 
          justifySelf='end' 
          alignItems={{base:'center', md:'center', lg:'flex-end'}}
        >
          <Box w={{base:'145px', md:'150px', lg:'160px'}} h={{base:'48px', md:'38px', lg:'48px' }}>
            <CTAButton
              href={categoryCTA.ctaRoute.route || '/'}
              fontSize={{base:'13px', md:'11px', lg:'13px'}}
              fontWeight='900'
              bgColor={categoryCTA.bgColor?.color || 'blue'}
              color={categoryCTA.textColor?.color || 'white'}
              width='100%'
              height='100%'
              _hover={{ bg: categoryNameColor, color: categoryCTA.textColor?.color || 'white'}}
              ctaText={categoryCTA.text}
              letterSpacing={{ base: '1px' }}
              lineHeight={{ base: '21px' }}
            />
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ThreeCategoriesWideHome;
