import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";

function EventHero(props) {
  const { header, description, heroBgImg } = props;

  let filter = "none";

  if (heroBgImg?.contrast || heroBgImg?.brightness) {
    filter = heroBgImg.contrast ? `contrast(${heroBgImg.contrast}%)` : "";
    filter += heroBgImg.brightness ? `brightness(${heroBgImg.brightness}%)` : "";
  }

  return (
    <Flex
      maxW="100%"
      h={{
        base: "234px",
        ms: "266px",
        msx: "290px",
        md: "35.5vw",
        mds: "35.5vw",
        lg: "34.72vw",
        lgs: "34.75vw",
        xl: "34.75vw",
      }}
      minH={{base:'400px',md:'900px'}}
      justifyContent="center"
      position="relative"
      id="hero-section"
      /* HERO IMAGE || NO IMAGE*/
      className={heroBgImg ? "hero-img" : "hero-primary-bg"}
      mb="25px"
      overflow="hidden"
      // border="1px"
      // zIndex="3"
    >
      {heroBgImg
        ? heroBgImg && (
            <Flex w="100vw" filter={filter}>
              <GatsbyImage
                image={heroBgImg.bgImage.asset.gatsbyImageData}
                alt={"wana hero image"}
              />
            </Flex>
          )
        : null}

      {/* TEXT CONTENT */}
      <Box
        w="100%"
        h="100%"
        position="absolute"
        top={{ base: "40px" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Heading
          color={"white"}
          fontFamily="Poppins"
          fontSize={{
            base: "33px",
            msx: "49px",
            md: "53px",
            lg: "57px",
            lgs: "61px",
            xl: "65px",
            sl: "70px",
            ssl: "75px",
            xsl: "80px",
            msl: "85px",
            ml: "90px",
          }}
          fontWeight="700"
          lineHeight={{
            base: "45px",
            msx: "73px",
            md: "77px",
            lg: "81px",
            lgs: "85px",
            xl: "89px",
            sl: "94px",
            ssl: "99px",
            xsl: "104px",
            msl: "109px",
            ml: "114px",
          }}
          letterSpacing={{ base: "0.55px", md: "1.08px" }}
          textAlign="center"
        >
          {header}
        </Heading>

        <Text
          color={"white"}
          fontSize={{
            base: "16px",
            md:'30px',
          }}
          fontFamily="Poppins"
          marginTop={{ base: "20px", md: "20px" }}
          padding={{ base: "0 20px", md: "0 40px" }}
          maxWidth={{ base: "90%", md: "95%" }}
          fontWeight="400"
          
          lineHeight={{
            base: "20px",
            md: "37.5px",
          }}
          letterSpacing={{ base: "4.8px", md: "4.8px" }}
          textAlign="center"
        >
          {description}
        </Text>
      </Box>

      {/* SVG BOTTOM IMAGE */}
      <Box
        w="100%"
        h={{
          base: "30%",
          md: "25%",
          lg: "30%",
          xl: "30%",
          xxl: "31%",
          sl: "32%",
          ssl: "40%",
        }}
        position="absolute"
        bottom={{ base: "-8px", ms: "-10px" }}
        // border="1px"
      >
        <svg
          viewBox="0 0 500 149"
          preserveAspectRatio="none"
          style={{ width: "100%", height: "100%" }}
        >
          <path
            fill={"white"}
            d="M-0.27,73.53 C192.15,3.48 325.90,143.59 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          ></path>
        </svg>
      </Box>
    </Flex>
  );
}

export default EventHero;
