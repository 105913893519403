import React, { CSSProperties } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';

import OneColumnText from './OneColumnText';
import { wrapperStyles } from './generic-column-section-style';

type TwoColumnsImgWrapperTextProps = {
  _rawTwoColumnsWrapperTextImageTxt: {
    [x: string]: any;
  }[];
  twoColumnsWrapperTextImageImg: {
    image: any;
  };
  twoColumnsWrapperTextAlignmentImageRight: boolean;
  styles: CSSProperties | undefined;
  [x: string]: any;
};

function TwoColumnsImgWrapperText(props: TwoColumnsImgWrapperTextProps) {
  const {
    _rawTwoColumnsWrapperTextImageTxt,
    twoColumnsWrapperTextImageImg,
    twoColumnsWrapperTextAlignmentImageRight = false,
  } = props;

  return (
    <Flex {...wrapperStyles}>
      <div style={{ wordBreak: 'break-word' }}>
        {twoColumnsWrapperTextImageImg?.image?.asset?.gatsbyImageData && (
          <Box
            textAlign="center"
            maxW={{ base: '100%', ms: 'unset' }}
            float={{ ms: twoColumnsWrapperTextAlignmentImageRight ? 'right' : 'left' }}
            p={{
              base: '0 0 1rem',
              ms: twoColumnsWrapperTextAlignmentImageRight ? '0 0 1rem 1rem' : '0 1rem 1rem 0'
            }}
          >
            <GatsbyImage
              style={{ width: 'inherit' }}
              image={twoColumnsWrapperTextImageImg.image.asset.gatsbyImageData}
              alt={twoColumnsWrapperTextImageImg.image.asset?.alt2 || 'wana image'}
            />
          </Box>
        )}
        <OneColumnText _rawOneColumnText={_rawTwoColumnsWrapperTextImageTxt} />
      </div>
    </Flex>
  );
};

export default TwoColumnsImgWrapperText;
