import * as React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import CTAButton from '../../components/CTAButton';

type featuredProductsHomeProps = {
  header: {
    headerText: string;
    textColor: { color: string };
  };
  containerColor: { color: string };
  image: {};
  featuredProductLeft: {
    accentHeader: { headerText: string; textColor: { color: string } };
    mainHeader: { headerText: string; textColor: { color: string } };
    productDescription: string;
    cta: {
      linkToPage: { slug: { current: string } };
      text: string;
      textColor: { color: string };
      bgColor: { color: string };
    };
  };
  featuredProductRight: {
    accentHeader: { headerText: string; textColor: { color: string } };
    mainHeader: { headerText: string; textColor: { color: string } };
    productDescription: string;
    cta: {
      linkToPage: { slug: { current: string } };
      text: string;
      textColor: { color: string };
      bgColor: { color: string };
    };
  };
};

function FeaturedProductsHome(props: featuredProductsHomeProps) {
  const { header, containerColor, image, featuredProductLeft, featuredProductRight } = props;
  const mainImage = getImage(image.asset);

  return (
    <Flex 
      maxW={{base:'87%', md:'87%', mds:'84%', lg:'84%', xl:'1200px'}} 
      mx='auto' 
      mt={{base:'-22.22vw', ms:'-23.9vw', msx:'-26.72vw', md:'-11vw', lg:'-12vw', xl:'-10vw', xxl:'-4.5vw', sl:'-4.2vw', ssl:'-7vw', ml:'-9vw'}} 
      flexDirection='column'
    >
      {/* HEADER */}
      <Box>
        <Text
          mb={{base:'-24px', md:'-20px', lg:'-38px', xl:'-52px'}}
          color={header.textColor?.color || 'white'}
          fontSize={{base:'66px', ms:'90px', md:'105px', lg:'152px', xl:'190px'}}
          fontWeight='900'
          letterSpacing={{base:'-1.94px', md:'-0.5px'}}
          lineHeight={{base:'70px', lg:'132.8px', xl:'166px'}}
          textAlign='center'
          textTransform='uppercase'
          opacity='0.2'
          sx={{
            WebkitTextStroke: '1px #272158',
            '@media (min-width: 768px)': { WebkitTextStroke: '1px #272158' },
          }}
        >
          {header.headerText}
        </Text>
      </Box>

      {/* CONTENT */}
      <Box
        w='100%'
        bgColor={containerColor?.color || 'primaryDarker'}
        zIndex='2'
        position='relative'
        display='flex'
        justifyContent={{base:'space-between'}}
        p={{
          base:'55px 44px', 
          md:'5vw 0 5vw 50px', 
          mds:'6vw 3vw 6vw 8vw', //895px - 1023px
          lg:'7vw 3vw 7vw 8vw',
          lgs:'8.5vw 6vw 8.5vw 10vw', 
          xl:'131px 100px 118px 142px',
        }}
        flexDirection={{base:'column', md:'row'}}
      >
        {/* PRODUCT */}
        <ProductCard
          accentHeader={featuredProductLeft.accentHeader}
          mainHeader={featuredProductLeft.mainHeader}
          description={featuredProductLeft.productDescription}
          cta={featuredProductLeft.cta}
          top={true}
        />

        {/* IMAGE */}
        <Box
          mx='auto'
          my={{base:'31px', md:'0'}}
          position={{base:'relative', md:'absolute'}}
          left={{md:'50%' }}
          top={{md:'50%' }}
          transform={{md:'translateY(-50%) translateX(-50%)'}}
          zIndex='1'
          maxW={{base:'172px', md:'29vw', mds:'29vw', lg:'29vw', xl:'437px'}}
        >
          <GatsbyImage image={mainImage} alt={'wana products'} />
        </Box>

        {/* PRODUCT */}
        <ProductCard
          accentHeader={featuredProductRight.accentHeader}
          mainHeader={featuredProductRight.mainHeader}
          description={featuredProductRight.productDescription}
          cta={featuredProductRight.cta}
          top={false}
        />
      </Box>
    </Flex>
  );
}

type productCardProps = {
  accentHeader: { headerText: string; textColor: { color: string } };
  mainHeader: { headerText: string; textColor: { color: string } };
  description: string;
  cta: {
    linkToPage: { slug: { current: string } };
    text: string;
    textColor: { color: string };
    bgColor: { color: string };
  };
  top: boolean;
};

const ProductCard = (props: productCardProps) => {
  const { accentHeader, mainHeader, description, cta } = props;

  return (
    <Flex flexDirection='column' alignItems={{base:'center', md:'flex-start' }}>
      {/* CATEGORY */}
      <Text
        color={accentHeader.textColor?.color || 'blueDarker'}
        fontSize={{base:'14px', md:'16px'}}
        fontWeight='800'
        textAlign={{base:'center', md:'left'}}
        letterSpacing={{base:'4.2px', md:'4.8px'}}
        lineHeight={{base:'20px'}}
        textTransform='uppercase'
        pb={{base:'10px'}}
      >
        {accentHeader.headerText}
      </Text>

      {/* SUBCATEGORY */}
      <Text
        color={mainHeader.textColor?.color || 'white'}
        textAlign={{ base: 'center', md: 'left' }}
        fontSize={{base:'34px', md:'36px'}}
        fontWeight='900'
        letterSpacing={{base:'-0.19px', md:'-0.2px'}}
        lineHeight={{base:'35px'}}
        textTransform='uppercase'
        pb={{base:'6px'}}
      >
        {mainHeader.headerText}
      </Text>

      {/* DESCRIPTION */}
      <Text
        maxW={{base:'100%', md:'211px', xl:'194px'}}
        color={'white'}
        textAlign={{base:'center', md:'left'}}
        fontSize={{base:'15px', md:'18px'}}
        fontWeight='600'
        letterSpacing={{base:'0'}}
        lineHeight={{base:'26px'}}
        // textTransform='capitalize'
        pb={{base:'16px'}}
      >
        {description}
      </Text>

      {/* BUTTON */}
      <CTAButton
        href={cta.ctaRoute.route || '/'}
        fontSize={{base:'13px'}}
        bgColor={cta.bgColor?.color || 'blue'}
        color={cta.textColor?.color || 'white'}
        width={{base:'160px'}}
        height={{base:'48px'}}
        _hover={{ bg: cta.textColor?.color || 'white', color: cta.bgColor?.color || 'blue'}}
        ctaText={cta.text}
        borderColor={cta.bgColor?.color || 'blue'}
      />
    </Flex>
  );
};

export default FeaturedProductsHome;
