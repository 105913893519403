import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import { Pagination } from "swiper";
import LinkWrapper from '../../components/LinkWrapper';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'; 
import { Box, Checkbox, Collapse, Text, Flex, Grid, GridItem } from '@chakra-ui/react';
import { motion } from 'framer-motion'
import { IoIosArrowDown } from 'react-icons/io';
import { MdCheck } from 'react-icons/md'
import { AiFillPlayCircle } from 'react-icons/ai';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import ThirdCommunitySection from '../ThirdCommunitySection';
import TextStrokeHeader from '../../components/TextStrokeHeader';
import OneColumnText from '../BlogPostSections/OneColumnText';
import CTAButton from '../../components/CTAButton/';

/* MODAL SETUP */
const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    padding: 0,
    border: 'none',
    borderRadius: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#___gatsby');

/* RICH TEST STYLES */
const oneColumnTextStyles = {
  fontSizes: {
    'normal': {base:'15px', md:'15px'},
    'h4': {base:'17px', md:'17px'},
    'h3': {base:'19px', md:'19px'},
    'h2': {base:'21px', md:'21px'},
    'h1': {base:'23px', md:'23px'},
  },
  fontWeights: {
    'normal': '400',
    'strong': '600',
  },
  lineHeights: {
    'normal': {base:'25px', md:'25px'},
    'h4': {base:'27px', md:'27px'},
    'h3': {base:'29px', md:'29px'},
    'h2': {base:'31px', md:'31px'},
    'h1': {base:'33px', md:'33px'},
  },
  letterSpacings: {
    'normal': {base:'0px', md:'0px'},
    'h4': {base:'0px', md:'0px'},
    'h3': {base:'0px', md:'0px'},
    'h2': {base:'0px', md:'0px'},
    'h1': {base:'0px', md:'opx'},
  },
  mbSection: {base:'0px', md:'0px'},
  mbContainers: {base:'20px', md:'20px'},
  hover: {borderColor:'primary'},
  linkFontStyle: 'italic',
  listsLeftMargin: {base:'30px', md:'60px'},
};

function FoundationDetails(props) {
  const { data } = props;
  
  /* MODAL CONTROL */
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState(null);

  const openModal = (item) => {
    setVideoURL(item.itemVideoUrl);
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden';
  };
  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'auto';
  };
  return (
    <>
      {/* SECTION */}
      <Box
        maxW="100%"
        h="auto"
        mx="auto"
        mt="5px"
        display="flex"
        flexDirection="column"
        position="relative"
        pt={{base:'0'}}
        pb={{base:'70px', md:'0'}}
        bgColor="grayBG"
      >
        {/* FOUNDATION DETAILS */}
        <Flex
          maxW="1440px"
          mx="auto"
          direction="column"
        >
          {/* SECTION HEADER */}
          <Box
            style={{zIndex:2}}
          >
            <TextStrokeHeader 
              text={data?.sectionHeader} 
              fontSize={{base:'12.92vw', ms:'10.5vw', msx:'10vw', md:'9.5vw', lg:'9.5vw', lgs:'11.5vw', xl:'186px'}}
              letterSpacing={{base:'-1.94px', md:'-1.5px'}}
              opacity={'0.1'}
              textStroke='1px'
              lineHeight={{base:'0.8', md:'0.8', lg:'0.9'}}
              noOfLines={{base:1}}
            />
          </Box>
        </Flex>

        {/* TITLE */}
        <Flex
          display={{base:'block', lgs:'none'}}
          w={{base:'100%', ssm:'95%', ms:'95%'}}
          mt={{base:'-1vw', ms:'-1vw', msx:'-1vw', md:'-1vw', mds:'-1.3vw', lg:'-1.5vw'}}
          mb={{base:'35px', ms:'35px', msx:'35px', mds:'0'}}
          mx="auto"
          style={{zIndex:2}}
        >
          <Text
            color="primary"
            fontSize={{base:'35px', ms:'37px', msx:'39px', md:'45px', mds:'45px', lg:'45px'}}
            fontWeight="800"
            letterSpacing={{base:'-0.5px', md:'-0.5px'}}
            lineHeight={{base:'1', md:'1'}}
            textAlign={{base:'center'}}
          >
            {data?.communityTitle}
          </Text>
        </Flex>

        {/* CONTENT */}
        <Flex 
          w={{base:'100%', msx:'100%', md:'100%', mds:'100%'}}
          direction="column"
          mx="auto"
        >
          <FoundationItem1 
            key={0} 
            item={data?.foundationDetailsItems[0]} 
            index={0} 
            openModal={() => openModal(data.foundationDetailsItems[0])}
            text={data?.foundationDetailsItems[0]._rawItemDetailsBlock[0].children[0].text} 
            cta={data.foundationDetailsItems[0].ctaLeft}
          />
          <SvgCurve top />
          <FoundationItem2 
            key={1} 
            item={data?.foundationDetailsItems[1]} 
            index={1} 
          />
          <SvgCurve top={false} />
          <FoundationItem3 
            key={2} 
            item={data?.foundationDetailsItems[2]} 
            index={2}
          />
          <FoundationItem4 
            key={3} 
            item={data?.foundationDetailsItems[3]} 
            index={3} 
          />
          <FoundationItem5 
            key={4} 
            item={data?.foundationDetailsItems[4]} 
            index={4} 
          />
          <FoundationItem6
            key={5} 
            item={data?.foundationDetailsItems[5]} 
            index={5} 
          />
          <FoundationItem7
            key={6} 
            item={data?.foundationDetailsItems[6]} 
            index={6} 
          />
        </Flex>
      </Box>

      {/* MODAL */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel='Example Modal'>
        <Box className='player-wrapper'>
          <ReactPlayer className='react-player' width='100%' height='100%' playing={modalIsOpen} auto url={videoURL} controls={true}/>
        </Box>
      </Modal>
    </>
  );
}

/* SVG CURVE */
const SvgCurve = ({ top }) => {
  const position = top ? {top:'0'} : {bottom:'0'};

  return (
    <Box
      w="100%"
      h={top ? {base:'-1187px', md:'-1187px', lg:'-1187px', xl:'-838px', sl:'558px'} : {base:'2561px',  ssm:'2501px', ms:'2666px', msx:'2653px', hhg:'2547px', md:'2138px', mds:'2102px', lg:'2156px', lgs:'2251px', xl:'2224px'}}
      position="absolute"
      left="0"
      style={{zIndex:0, ...position}}
    >
      {/* TOP SVG */}
      {top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M-1.40,97.28 C150.26,100.02 278.49,70.05 550.41,95.36 L500.00,0.00 L0.00,0.00 Z"
          >
          </path>
        </svg> 
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M-158.85,-81.40 C-211.91,59.70 538.65,3.45 608.63,0.48 L566.31,307.40 L-110.89,307.40 Z"
          >
          </path>
        </svg> 
      )}
    </Box>
  );
};

/* SVG CURVE COLORED */
const SvgCurveColored = ({ top }) => {
  const position = top ? {top:'0'} : {bottom:'0'};

  return (
    <Box
      w="100%"
      h={top ? {base:'70%', md:'40%', lg:'44%', xl:'45%'} : {base:'27%', md:'26%', lg:'30%', lgs:'27%', xl:'26%'}}
      position="absolute"
      left="0"
      style={{zIndex:0, ...position}}
    >
      {/* TOP SVG */}
      {top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="#2F2865"
            d="M0.00,49.98 C150.00,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
          >
          </path>
        </svg> 
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="#2F2865"
            d="M-390,-90.42 C44.55,140.80 150,-55.87 800,49.83 L500.00,150.00 L0.00,150.00 Z"
          >
          </path>
        </svg> 
      )}
    </Box>
  );
};

/* FOUNDATION - FIRST ITEM */
const FoundationItem1 = ({ item, index, openModal, text=null, cta }) => {

  if (!item) {
    return null;
  }

  const logo = getImage(item?.logoImg?.image?.asset)
  const alt = item?.logoImg?.altText

  const isPar = index % 2 === 0;

  const handleGoToStates = () => {
    window.scrollTo({
      top: 2600,
      behavior: "smooth",
    });
  }

  return(
    <Flex 
      maxW="1440px"
      alignItems="center"
      direction={{base:'column-reverse', mds: 'row'}}
      justifyContent={'flex-start'}
      mt={{base:'-28px', md:'-26px', mds:'-37px', lg:'-72px', lgs:'-80px', xl:'-101px'}}
      mb={{base:'8vw', md:'-4vw', lg:'4vw', lgs:'-1.53vw', xl: '-8px'}}
      style={{zIndex:3}}
      mx={{xl:'auto'}}
      bgColor={'primary'}
    >
      {/* TEXT SECTION */}
      <Flex
        w={{base:'100%', mds:!isPar ? '50%' : '60%', lg:'50%'}}
        direction="column"
        p={{base:'35px 2.5% 47px 2.5%', md:'44px 5% 59px 5%', mds:'30px 5.82vw 30px 5.68vw', lg:'69px 85px 91px 90px', lgs:'0px 70px 91px 168px', xl:'0px 70px 91px 168px'}}
        bgColor={'transparent'}
        style={{zIndex:2}}
      >
        {/* TOP TEXT */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          ml={{base:'10%', ms:'15%', msx:'23%', md:'-4%', mds:'17%', lg:'0%', lgs:'4%', xl:'72px'}}
        >
          <Flex
            w={{base:'49%', ms:'25%', msx:'26%', md:'14%', mds:'45%', lg:'48%', lgs:'43%', xl:'46%'}}
            mb={{base:'33px', ms:'20px', msx:'27px', md:'7px', mds:'13px', lg:'22px', lgs:'13px', xl:'10px'}}
          >
            <GatsbyImage
              image={logo}
              alt={alt} 
            />
          </Flex>
          <Text
            color={'#D8DF26'}
            border={'1px'}
            width={'1px'}
            height={{base:'66px', ms:'70px', msx:'70px', md:'80px', mds:'76px', lg:'75px', lgs:'80px', xl:'83px'}}
            mb={{base:'20px', md:'10px', lg:'14px'}}
            mx={{base:'20px', md:'10px', lg:'14px'}}
          >
          </Text>
          <Text
            color={ 'white' }
            fontSize={{base:'29px', ms:'30px', msx:'31px', md:'32px', mds:'33px', lg:'34px', lgs:'35px', xl:'36px'}}
            fontWeight="800"
            letterSpacing={{base:'-0.28px', md:'-0.28px'}}
            lineHeight={{base:'1.5', md:'1.5'}}
            mb={{base:'20px', md:'10px', lg:'14px'}}
          >
            {item?.itemTitle}
          </Text>
        </Box>

        {/* BOTTOM TEXT */}
        {item?._rawItemDetailsBlock && (
          <Box
            pl={{base:'0', mds:'2.43vw', xl:'72px'}}
            textAlign={{base:'center', lg:'left'}}
          >
            <OneColumnText 
              _rawOneColumnText={item._rawItemDetailsBlock} 
              styles={{...oneColumnTextStyles, fontColor: 'white'}}
            />
            <Flex
              justifyContent={{base:'center', mds:'left'}}
            >
              <CTAButton
                fontSize={{base:'10px', md:'11px', lg:'13px'}}
                bgColor={cta.bgColor?.color || 'white'}
                color={cta.textColor?.color || 'primary'}
                width={{base:'272px', ms:'280px', msx:'264px', md:'342px', mds:'313px', lg:'342px', lgs:'342px', xl:'342px'}} 
                height={{base:'48px', ms:'48px', msx:'48px', md:'48px', mds:'48px', lg:'48px', lgs:'48px', xl:'48px'}}
                _hover={{bg: cta.textColor?.color, color: cta.bgColor?.color}}
                ctaText={cta.text}
                onClick={handleGoToStates}
              />
            </Flex>
          </Box>
        )}
      </Flex>

      {/* MEDIA */}
      <Flex
        w={{base:'95%', md:'50vw', mds:'47vw', lg:'42vw', lgs:'42vw', xl:'606px'}}
        h={{base:'auto', md:'auto', mds:'32.4vw', lg:'30.35vw', lgs:'30.35vw', xl:'437px'}}
        mx={{base:'auto', mds:'0'}}
        position="relative"
        style={{zIndex:1}}
        mt={{base:'-3px', ms:'0px', msx:'1px', md:'1px', mds:'49px', lg:'58px', lgs:'80px', xl:'100px'}}
        mb={!isPar ? {base:'60px', msx:'60px', md:'70px', mds:'20px', lg:'0'} : {base:'5px', msx:'5px', mds:'20px', lg:'0'}}
      >
        <GatsbyImage 
          image={item?.itemImage?.asset?.gatsbyImageData}
          alt={'foundation item image'}
        />

        {/* PLAY BUTTON IF MEDIA IS A VIDEO */}
        {item?.itemVideoUrl && (
          <Box
            w="100%"
            h="100%"
            position="absolute"
            top="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {/* ICON */}
            <Box
              w="auto"
              h="auto"
              borderRadius="full"
              cursor='pointer'
              style={{zIndex:2}}
              onClick={openModal}
            >
              <AiFillPlayCircle style={{ fontSize: '92px', color: '#272158' }} />
            </Box>

            {/* ICON WHITE BACKGROUND */}
            <Box
              w="46px"
              h="46px"
              bgColor="white"
              borderRadius="full"
              position="absolute"
            />
          </Box>
        )}
      </Flex>

    </Flex>
  );
};
/* FOUNDATION - WHAT ARE LIFE ESSENTIALS? */
const FoundationItem2 = ({ item, index, text=null }) => {

  if (!item) {
    return null;
  }

  const isPar = index % 2 === 0;
  const logo = getImage(item?.logoImg?.image?.asset)
  const alt = item?.logoImg?.altText

  return(
    <Flex 
      maxW="1440px"
      alignItems="center"
      direction={'column'}
      justifyContent={'center'}
      mt={{base:'116px', md:'116px', mds:'116px', lg:'116px', lgs:'116px', xl:'116px'}}
      mb={!isPar ? {base:'10vw', md:'8vw', mds:'4.5vw', lg:'4.5vw', lgs:'8.6vw', xl:'120px'} : {base:'8vw', md:'-4vw', lg:'4vw', lgs:'-1.53vw', xl: '-8px'}}
      style={{zIndex:3}}
      mx={{xl:'auto'}}
    >
      {/* TEXT SECTION */}
      <Flex
        w={{base:'100%'}}
        direction="column"
        style={{zIndex:2}}
      >
        {/* TOP TEXT */}
        <div 
          className='foundationTextBox'
        >
          <Flex
            alignItems="center"
            justifyContent={'center'}
            w={{base:'11%', ms:'7%', msx:'7%', md:'7%', mds:'7%', lg:'5%', lgs:'5%', xl:'5%'}}
            mb={{base:'33px', ms:'20px', msx:'27px', md:'7px', mds:'13px', lg:'22px', lgs:'13px', xl:'10px'}}
          >
            <GatsbyImage
              image={logo}
              alt={alt} 
            />
          </Flex>
          <Text
            color={'primary'}
            fontSize={{base:'40px', ms:'55px', msx:'60px', md:'61px', mds:'62px', lg:'63px', lgs:'64px', xl:'64px'}}
            fontWeight="600"
            letterSpacing={{base:'-0.28px', md:'-0.28px'}}
            ml={{base:'12%', lg:'29%'}}
            mr={{base:'11%', lg:'29%'}}
            mb={{base:'0%', ms:'-2%', msx:'0%', md:'0%', mds:'0%', lg:'1%', lgs:'-1%', xl:'2%'}}
            lineHeight={{base:'1.5', md:'1.5'}}
            textAlign={{base:'center'}}
          >
            {item?.itemTitle}
          </Text>
        </div>

        {/* BOTTOM TEXT */}
        {item?._rawItemDetailsBlock && (
          <Box
            p={{base:'43px 45px 18px 19px', md:'37px 57px 19px 49px', mds:'40px 54px 19px 47px', lg:'38px 54px 19px 45px', lgs:'60px 97px 19px 168px', xl:'60px 97px 19px 168px'}}
            ml={{base:'12%', ms:'12%', msx:'6%', md:'6%', mds:'8%', lg:'10%', lgs:'12%', xl:'14%'}}
            mr={{base:'12%', ms:'12%', msx:'6%', md:'6%', mds:'8%', lg:'10%', lgs:'12%', xl:'14%'}}
            textAlign={{base:'center'}}
            bgColor={'#E4E3F2'}

          >
            <OneColumnText 
              _rawOneColumnText={item._rawItemDetailsBlock} 
              styles={{...oneColumnTextStyles, fontColor: 'primary'}}
            />

          </Box>
        )}
      </Flex>
    </Flex>
  );
};
/* FOUNDATION - CAROUSEL */
const FoundationItem3 = ({ item, index, text=null }) => {

  if (!item) {
    return null;
  }

  return(
    <Flex 
      maxW="1440px"
      alignItems="center"
      direction={'column'}
      justifyContent={'center'}
      mt={{base:'-5px', md:'-14px', mds:'8px', lg:'0px', lgs:'-56px', xl:'-56px'}}
      mb={{base:'-30px', md:'-20px', mds:'-20px', lg:'-20px', lgs:'-20px', xl:'-20px'}}
      style={{zIndex:3}}
      mx={{xl:'auto'}}
    >
      <Box
        w={{base:'1210px', ms:'1453px', msx:'1577px', md:'1692px', mds:'1692px', lg:'1692px', lgs:'1692px', xl:'1692px'}}
        h={{base:'300px', ms:'300px', msx:'300px', md:'300px', mds:'300px', lg:'300px', lgs:'300px', xl:'300px'}}
      >
        <Swiper
          slidesPerView={4}
          spaceBetween={25}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
        {item?.foundationCarouselItems?.map((card,indx)=>{
          return(
            <SwiperSlide
              key={indx}
            >
              <Flex
                alignItems="left"
                justifyContent={'flex-start'}
                position={'absolute'}
                top={'37px'}
                left={'41px'}
                w={{base:'9%', ms:'9%', msx:'9%', md:'9%', mds:'9%', lg:'9%', lgs:'9%', xl:'9%'}}
              >
                <GatsbyImage
                  image={card?.sliderLogoImg?.image?.asset?.gatsbyImageData}
                  alt={card?.sliderLogoImg?.altText}
                />
              </Flex>
              <Box
                ml={{base:'12%', ms:'12%', msx:'6%', md:'6%', mds:'8%', lg:'10%', lgs:'10%', xl:'10%'}}
                mr={{base:'12%', ms:'12%', msx:'6%', md:'6%', mds:'8%', lg:'10%', lgs:'12%', xl:'14%'}}
                textAlign={{base:'left'}}
                cursor={'pointer'}
              >
                <Text
                  color="primary"
                  fontSize={{base:'36px'}}
                  fontWeight={{base:'700'}}
                  lineHeight={{base:'1.5'}}
                  letterSpacing={{base:'1.2'}}
                  textAlign={{base:'left'}}
                  mt={{base:'8%', ms:'8%', msx:'8%', md:'8%', mds:'8%', lg:'8%', lgs:'8%', xl:'8%'}}
                  mb={{base:'12%', ms:'12%', msx:'6%', md:'6%', mds:'8%', lg:'10%', lgs:'12%', xl:'8%'}}
                >
                  {card?.itemCarouselTitle}
                </Text>
                <Text
                  color="primary"
                  fontSize={{base:'13px'}}
                  lineHeight={{base:'1.5'}}
                  letterSpacing={{base:'1.2'}}
                  textAlign={{base:'left'}}
                  mt={{base:'12%', ms:'12%', msx:'6%', md:'6%', mds:'8%', lg:'10%', lgs:'12%', xl:'4%'}}
                >
                  {card?.itemCarouselDetails}
                </Text>
              </Box>
            </SwiperSlide>
          )
          })}
        </Swiper>
      </Box>
    </Flex>
  );
};
/* FOUNDATION - IN ADDITION */
const FoundationItem4 = ({ item, index, text=null }) => {

  if (!item) {
    return null;
  }

  const isPar = index % 2 === 0;
  const logo = getImage(item?.logoImg?.image?.asset)
  const alt = item?.logoImg?.altText

  return(
    <Flex 
      maxW="1440px"
      alignItems="center"
      direction={'column'}
      justifyContent={'center'}
      mt={{base:'116px', md:'116px', mds:'116px', lg:'116px', lgs:'116px', xl:'116px'}}
      mb={!isPar ? {base:'10vw', md:'8vw', mds:'4.5vw', lg:'4.5vw', lgs:'8.6vw', xl:'120px'} : {base:'8vw', md:'-4vw', lg:'4vw', lgs:'-1.53vw', xl: '-8px'}}
      style={{zIndex:3}}
      mx={{xl:'auto'}}
    >
      {/* TEXT SECTION */}
      <Flex
        w={{base:'100%'}}
        direction="column"
        style={{zIndex:2}}
      >
        {/* BOTTOM TEXT */}
        {item?._rawItemDetailsBlock && (
          <Box
            p={{base:'40px 45px 18px 19px', md:'43px 57px 19px 49px', mds:'43px 54px 19px 47px', lg:'43px 54px 19px 45px', lgs:'43px 97px 19px 168px', xl:'43px 97px 19px 168px'}}
            ml={{base:'12%', ms:'12%', msx:'6%', md:'6%', mds:'8%', lg:'10%', lgs:'12%', xl:'14%'}}
            mr={{base:'12%', ms:'12%', msx:'6%', md:'6%', mds:'8%', lg:'10%', lgs:'12%', xl:'14%'}}
            textAlign={{base:'center'}}
            bgColor={'#272158'}

          > 
            <div
              className='foundationTextBox'
            >
              <Flex
                alignItems="center"
                justifyContent={'center'}
                w={{base:'16%', ms:'11%', msx:'9%', md:'9%', mds:'8%', lg:'7%', lgs:'7%', xl:'7%'}}
                mb={{base:'24px'}}
                mx={{xl:'auto'}}
              >
                <GatsbyImage
                  image={logo}
                  alt={alt} 
                />
              </Flex>
            </div>
            <OneColumnText 
              _rawOneColumnText={item._rawItemDetailsBlock} 
              styles={{...oneColumnTextStyles, fontColor: 'white'}}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
/* FOUNDATION - SEE WHAT'S HAPPENING IN YOUR STATE */
const FoundationItem5 = ({ item, index, text=null }) => {

  if (!item) {
    return null;
  }

  const { foundationByState, itemTitle } = item
  console.log(foundationByState)
  const [checked, setChecked] = React.useState([] as any);
  const [orgsCounter, setOrgsCounter] = React.useState(0);
  const [filterOptionOpen, setFilterOptionOpen] = React.useState(true);
  const [orgsToShow, setOrgsToShow] = React.useState([] as any);

  const handleChange = (e) => {
    e.preventDefault(e)
    let updatedList = [...checked];
    if (e.target.checked) {
      updatedList = [...checked, e.target.value];
    } else {
      updatedList.splice(checked.indexOf(e.target.value), 1);
    }
    setChecked(updatedList);
    manageOrgs(updatedList)
  }
  console.log(checked)

  const manageOrgs = (states) => {
    let organizations : string[] = [];
    foundationByState.map(org => {
      console.log(org.itemByStateTitle)
      states.filter(state=>{
        console.log(state)
        if(org.itemByStateTitle === state){
          console.log(org.organizationByState)
          organizations = [...organizations, ...org.organizationByState]
        }
        console.log(organizations)
      })
    })
    let hash = {};
    organizations = organizations.filter(o => hash[o.organizationTitle] ? false : hash[o.organizationTitle] = true);
    console.log(organizations);
    setOrgsToShow(organizations)
    console.log(organizations.length)
    setOrgsCounter(organizations.length)
  } 


  return(
    <Flex 
      maxW="1440px"
      alignItems="center"
      direction={'column'}
      justifyContent={'center'}
      mt={{base:'100px'}}
      mb={{base:'10vw', md:'8vw', mds:'4.5vw', lg:'4.5vw', lgs:'8.6vw', xl:'120px'}}
      style={{zIndex:3}}
      mx={{base:'auto'}}
    >
      {/* TEXT SECTION */}
      <Flex
        w={{base:'100%'}}
        direction="column"
        style={{zIndex:2}}
      >
        {/* TOP TEXT */}
        <div 
          className='foundationTextBox'
        >
          <Text
            color={'primary'}
            fontSize={{base:'40px', ms:'55px', msx:'60px', md:'61px', mds:'62px', lg:'63px', lgs:'64px', xl:'64px'}}
            fontWeight="600"
            letterSpacing={{base:'-0.28px', md:'-0.28px'}}
            ml={{base:'12%', lg:'13%'}}
            mr={{base:'11%', lg:'14%'}}
            mb={{base:'7%', lg:'7%'}}
            lineHeight={{base:'1.5', md:'1.5'}}
            textAlign={{base:'center'}}
          >
            {item?.itemTitle}
          </Text>
        </div>
        <Flex
          maxW="1440px"
          direction={{base: 'column', md: 'row'}}
          alignItems={{base:'center', md:'flex-start'}}
        >
          <Box
            w={{base:'56%', sm:'50%', ssm:'40%', ms:'26%', msx:'26%', md:'34%', mds:'26%', lg:'22%', lgs:'23%', xl:'23%'}}
            alignItems="center"
            position="relative"
            onClick={() => {
              filterOptionOpen ? setFilterOptionOpen(false) : setFilterOptionOpen(true);
            }}
          >
            <Flex
              justifyContent="space-between"
              borderBottom={{base:'1px'}}
              borderColor="primary"
              mb={'10px'}
              cursor={'pointer'}
            >
              {/* FILTER OPTIONS */}
                <Text
                  fontSize={{base:'15px', md:'18px'}}
                  fontWeight={{base:'800'}}
                  color="primary"
                  textTransform="uppercase"
                  mr={'10px'}
                  >
                  {'Filter by State'}
                </Text>
                <motion.div 
                  animate={{
                    rotate: filterOptionOpen ? 180 : 0,
                    transition: { duration: 0.65 },
                  }}
                >
                  <IoIosArrowDown style={{color:'#272158', width:'20px', height:'20px'}}/>
                </motion.div>
            </Flex>
              <Collapse startingHeight={0} in={filterOptionOpen}>
                <Flex
                  w="100%"
                  h="auto"
                  direction="column"
                  mt={{base:'0px', md:'0px'}}
                  maxH={{
                    base: 'auto',
                    md:'85vh',
                  }}
                  overflowY="scroll"
                  className="hide-scrollbar hide-scrollbar-firefox"
                  ml={{md:'21px', lg:'0px'}}
                >
                  {/* FILTER OPTIONS */}
                  <Flex
                    w="100%"
                    direction="column"
                  > 
                    {foundationByState.map((item,indx)=>{
                      return (
                        <Checkbox
                          key={indx}
                          size='md' 
                          onChange={handleChange}
                          value={item.itemByStateTitle}
                          icon={<MdCheck />}
                          colorScheme='primary'
                        >
                          <motion.div whileHover={{ marginLeft: '10px' }}>
                            <Text
                              fontSize={{base:'18px'}}
                              fontWeight="400"
                              color="primary"
                              ml={{base:'5px'}}
                              mb={{base:'10px'}}
                              mt={{base:'9px'}}
                              textTransform="capitalize"
                            >
                              {item.itemByStateTitle}
                            </Text>
                          </motion.div>
                        </Checkbox>
                      )
                    })}
                  </Flex>
                </Flex>
              </Collapse>
          </Box>
          <Flex
            w="100%"
            p={orgsCounter < 4 ? {base:'55px 0px 0px 0px', md:'59px 67px 0px 0px', mds:'64px 0px 0px 23px', lg:'5px 0px 0px 52px', lgs:'6px 0px 19px 26px', xl:'4px 0px 19px 45px'} :
               orgsCounter < 7 ? {base:'55px 0px 0px 0px', md:'61px 67px 0px 0px', mds:'71px 0px 0px 38px', lg:'71px 0px 0px 52px', lgs:'74px 0px 19px 22px', xl:'71px 0px 19px 30px'} :
                                 {base:'55px 0px 0px 0px', md:'159px 82px 0px 21px', mds:'165px 0px 0px 22px', lg:'128px 0px 0px 52px', lgs:'117px 0px 19px 33px', xl:'123px 0px 19px 45px'}
            }
            maxH={{base:'auto', md:'83px'}}
            direction={{base:'column', md:'row'}}
            alignItems="center"
          >
            <Grid 
              templateColumns={{base:'repeat(1, 1fr)', md:'repeat(2, 1fr)', lg:'repeat(3, 1fr)'}} 
              gap={6}
            >
              {
                orgsToShow.map((item,indx)=>{
                  return (
                    <LinkWrapper key={indx} href={item.orgUrl}>
                        <GridItem>
                          <Flex
                            direction={{base: 'column', md: 'row-reverse'}}
                            maxW={{base:'200px', md:'240px'}}
                            maxH={{base:'auto', md:'83px'}}
                            h="100%"
                            _last={{mr:'0'}}
                            mr={{base:'0', md:'35px'}}
                            mb={{base:'40px', md:'0'}}
                            alignItems={{base:'flex-end', md:'center'}}
                          >
                            {
                              item.isSponsored 
                                ? 
                                <Text
                                  color={'#b6b6b6e'}
                                  fontWeight="bold"
                                >
                                  *
                                </Text>
                                : 
                                null
                            }
                            <GatsbyImage 
                              image={item?.orgImg?.image?.asset?.gatsbyImageData}
                              alt={item?.orgImg?.altText}
                              objectFit="contain"
                            />
                          </Flex>
                        </GridItem>
                  </LinkWrapper>
                  )
                })
              }
            </Grid>
          </Flex>
        </Flex>
        
        {/* BOTTOM TEXT */}
        {
          orgsCounter > 0 
          ?
            <Flex
              justifyContent={'center'}
              mt={'100px'}
              fontSize={{base:'18px'}}
            >
              <Text
                color={'#b6b6b6e'}
                fontWeight="bold"
              >
                *
              </Text>
              <Text
                color="primary"
                ml={{base:'15px'}}
              >
                Sponsored by the Wana Brands Corporation
              </Text>
            </Flex>
          :
          null
        }
      </Flex> 
    </Flex>
  );
};
/* FOUNDATION - QUOTATION */
const FoundationItem6 = ({ item, index, text=null }) => {

  if (!item) {
    return null;
  }

  const mainText = item._rawItemDetailsBlock[0].children[0].text
  const quotationAuthor = item._rawItemDetailsBlock[1].children[0].text
  const authorRole = item._rawItemDetailsBlock[2].children[0].text

  return(
    <Flex 
      maxW="1440px"
      alignItems="center"
      direction={{base:'column', md:'row'}}
      justifyContent={'center'}
      mt={{base:'116px', md:'116px', mds:'116px', lg:'116px', lgs:'116px', xl:'116px'}}
      // mb={{base:'120px', md:'120px', mds:'120px', lg:'120px', lgs:'120px', xl:'120px'}}
      p={{base:'0px 45px 0px 45px', ms:'0px 63px 0px 85px', md:'0px 63px 0px 85px', mds:'0px 117px 0px 157px', lg:'0px 202px 0px 277px', lgs:'0px 301px 0px 368px', xl:'0px 301px 0px 393px'}}
      style={{zIndex:3}}
      mx={{xl:'auto'}}
    >
      <Flex
        w={{base:'100%', md:'50%'}}
        direction="column"
        alignItems="flex-end"
        style={{zIndex:2}}
        mb={{base:'40px', md:'0px'}}
      >
        <GatsbyImage 
          image={item?.itemImage?.asset?.gatsbyImageData}
          alt={'quotation from founder'}
          className={'quotationImageStyles'}
        />
      </Flex>
      <Flex
        direction={'column'}
        ml={{base:'0px', md:'90px'}}
        w={{base:'72%', md:'100%'}}
      >
        <Text
          fontSize={{base:'25px', md:'30px'}}
          fontStyle={'italic'}
          lineHeight={'40px'}
          color={'primary'}
          letterSpacing={{base:'0.5px', md:'0.5px'}}
          mb={{base:'20px', md:'20px', mds:'20px', lg:'20px', lgs:'20px', xl:'20px'}}
        >
          {mainText}
        </Text>
        <Text
          fontSize={{base:'20px', md:'24px'}}
          color={'#7FC344'}
          pr={{base:'0px', md:'290px', mds:'292px', lg:'290px', lgs:'302px', xl:'290px'}}
          mb={{base:'7px', md:'7px', mds:'7px', lg:'7px', lgs:'7px', xl:'7px'}}
        >
          {quotationAuthor}
        </Text>
        <Text
          color={'primary'}
          fontWeight="600"
        >
          {authorRole}
        </Text>
        <Flex>
          <Box
            position={'relative'}
            left={{base:'-21px', ssm:'-36px', ms:'-38px', msx:'-39px', hhg:'-38px', md:'-34px', mds:'-34px', lg:'-34px', lgs:'-34px', xl:'-34px'}}
            top={{base:'-304px',  ssm:'-285px', ms:'-252px', msx:'-249px', hhg:'-211px', md:'-291px', mds:'-291px', lg:'-291px', lgs:'-291px', xl:'-291px'}}
          >
            <svg 
              viewBox="0 0 600 600"
              fillOpacity="50%" 
              preserveAspectRatio="none" 
              style={{width:'100%', height:'100%'}}
            >
              <path 
                fill="#2F2865" 
                d="M44.33,88.474v15.377h38.417v82.745H0v-82.745h0.002V88.474c0-31.225,8.984-54.411,26.704-68.918  C38.964,9.521,54.48,4.433,72.824,4.433v44.326C62.866,48.759,44.33,48.759,44.33,88.474z M181.107,48.759V4.433  c-18.343,0-33.859,5.088-46.117,15.123c-17.72,14.507-26.705,37.694-26.705,68.918v15.377h0v82.745h82.744v-82.745h-38.417V88.474  C152.613,48.759,171.149,48.759,181.107,48.759z"
              />
            </svg>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
/* FOUNDATION - WORK WITH THE WBF */
const FoundationItem7 = ({ item, index, text=null }) => {

  if (!item) {
    return null;
  }

  const isPar = index % 2 === 0;
  const logo = getImage(item?.logoImg?.image?.asset)
  const alt = item?.logoImg?.altText

  return(
    <Flex 
      maxW="1440px"
      alignItems="center"
      direction={'column'}
      justifyContent={'center'}
      mt={{base:'116px', md:'116px', mds:'116px', lg:'116px', lgs:'116px', xl:'116px'}}
      mb={{base:'250px', md:'250px', lg:'250px', lgs:'250px', xl: '250px'}}
      style={{zIndex:3}}
      mx={{xl:'auto'}}
    >
      {/* TEXT SECTION */}
      <Flex
        w={{base:'100%'}}
        direction="column"
        style={{zIndex:2}}
      >
        {/* TOP TEXT */}
        <div 
          className='foundationTextBox'
        >
          <Flex
            alignItems="center"
            justifyContent={'center'}
            w={{base:'11%', ms:'7%', msx:'7%', md:'7%', mds:'7%', lg:'5%', lgs:'5%', xl:'5%'}}
            mb={{base:'33px', ms:'20px', msx:'27px', md:'7px', mds:'13px', lg:'22px', lgs:'13px', xl:'10px'}}
          >
            <GatsbyImage
              image={logo}
              alt={alt} 
            />
          </Flex>
          <Text
            color={'primary'}
            fontSize={{base:'40px', ms:'55px', msx:'60px', md:'61px', mds:'62px', lg:'63px', lgs:'64px', xl:'64px'}}
            fontWeight="600"
            letterSpacing={{base:'-0.28px', md:'-0.28px'}}
            ml={{base:'12%', lg:'15%'}}
            mr={{base:'11%', lg:'14%'}}
            mb={{base:'0%', ms:'-2%', msx:'0%', md:'0%', mds:'0%', lg:'1%', lgs:'-1%', xl:'2%'}}
            lineHeight={{base:'1.5', md:'1.5'}}
            textAlign={{base:'center'}}
          >
            {item?.itemTitle}
          </Text>
        </div>

        {/* BOTTOM TEXT */}
        {item?._rawItemDetailsBlock && (
          <Box
            p={{base:'43px 45px 18px 19px', md:'37px 57px 19px 49px', mds:'40px 54px 19px 47px', lg:'38px 54px 19px 45px', lgs:'60px 97px 19px 168px', xl:'0px 184px 19px 168px'}}
            ml={{base:'12%', ms:'12%', msx:'6%', md:'6%', mds:'8%', lg:'10%', lgs:'12%', xl:'14%'}}
            mr={{base:'12%', ms:'12%', msx:'6%', md:'6%', mds:'8%', lg:'10%', lgs:'12%', xl:'14%'}}
            textAlign={{base:'center'}}
            
          >
            <OneColumnText 
              _rawOneColumnText={item._rawItemDetailsBlock} 
              styles={{...oneColumnTextStyles, fontColor: 'primary'}}
            />

          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default FoundationDetails;
