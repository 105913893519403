import * as React from "react";
import {  Flex } from "@chakra-ui/react";
import LinkWrapper from "../../../components/LinkWrapper";
import OneColumnText from "../../BlogPostSections/OneColumnText";

function ButtonCTA(props) {
  const { text, link, color, bgColor, inHero } = props;

  const [hover, setHover] = React.useState(false);

  const fontSize = !inHero
    ? { base: "16px", md: "16px", mds: "16px", lg: "16px", lgs: "17px", xl: "18px" }
    : {
        base: "12px",
        md: "14px",
        mds: "15px",
        lg: "16px",
        lgs: "17px",
        xl: "18px",
        xxl: "19px",
        sl: "20px",
        ssl: "21px",
        xsl: "22px",
        msl: "23px",
        ml: "24px",
      };
  /* STYLES */
  const oneColumnTextStyles = {
    fontColor: !hover ? color : bgColor,
    fontSizes: {
      small: fontSize,
      normal: fontSize,
      h4: fontSize,
      h3: fontSize,
      h2: fontSize,
      h1: fontSize,
    },
    fontWeights: {
      normal: "400",
      strong: "600",
    },
    lineHeights: {
      small: { base: "1", md: "1" },
      normal: { base: "1", md: "1" },
      h4: { base: "1", md: "1" },
      h3: { base: "1", md: "1" },
      h2: { base: "1", md: "1" },
      h1: { base: "1", md: "1" },
    },
    letterSpacings: {
      small: { base: "-0.14px", md: "-0.14px" },
      normal: { base: "-0.14px", md: "-0.14px" },
      h4: { base: "-0.14px", md: "-0.14px" },
      h3: { base: "-0.14px", md: "-0.14px" },
      h2: { base: "-0.14px", md: "-0.14px" },
      h1: { base: "-0.14px", md: "-0.14px" },
    },
    textAlign: { base: "center", md: "center" },
    mbSection: { base: "0px", md: "0px" },
    mbContainers: { base: "0px", md: "0px" },
    hover: { borderColor: "primary" },
    linkFontStyle: "italic",
    listsLeftMargin: { base: "30px", md: "60px" },
    listsRightMargin: { base: "20px", mds: "10px" },
    listItemBottomMargin: { base: "10px", mds: "10px" },
  };
  return (
    <LinkWrapper href={link}>
      <Flex
        width="150px"
        height={{ sm: "48px" }}
        justifyContent="center"
        alignItems="center"
        color={!hover ? color : bgColor}
        bgColor={
          !hover
            ? bgColor
            : color === "#F1F0FA" || color === "#FFFFFF"
            ? "blue"
            : color
        }
        mx="auto"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        border="1px"
        borderColor={
          color === "#F1F0FA" || color === "#FFFFFF" ? "primary" : bgColor
        }
      >
        <OneColumnText
          _rawOneColumnText={text}
          styles={oneColumnTextStyles}
          extra={"cta"}
        />
      </Flex>
    </LinkWrapper>
  );
}

export default ButtonCTA;
