import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, Flex } from '@chakra-ui/react';
import { PortableText, PortableTextReactComponents } from '@portabletext/react';

import LinkWrapper from '../../components/LinkWrapper';
import { defaultStyles } from './generic-column-section-style';

import '../../styles/styles.scss';

function OneColumnText(props) {
  const { _rawOneColumnText, image, withLink, imgLink, revertOrder, extra } = props;
  const handleColor = () => {
    let myColor = defaultStyles.fontColor;

    switch (myColor) {
      case "primary":
        myColor = "#272158";
        break;
      case "#272158":
        myColor = "#272158";
        break;
      case "teal":
        myColor = "#7fd5d1";
        break;
      case "#7fd5d1":
        myColor = "#7fd5d1";
        break;
      case "white":
      case "#ffffff":  
        myColor = "#fff";
        break;
      default:
        myColor = "#272158";
        break;
    }

    return myColor;
  };

  const components = {
    marks: {
      link: ({value, children}) => {
        const target = (value?.href || '').startsWith('http') ? '_blank' : undefined
        return (
          <a href={value?.href} target={target} rel={target === '_blank' ? 'noindex nofollow' : ''} className='blog-raw-content-a'>{children}</a>
        )
      },
    },
    listItem: {
      bullet: ({children}) => <li className='blog-raw-content-li'>{children}</li>,
      number: ({children}) => <li className='blog-raw-content-li'  >{children}</li>,
      left: ({children}) => <Box textAlign="left">{children}</Box>,
      center: ({children}) => <Box textAlign="center">{children}</Box>,
      right: ({children}) => <Box textAlign="right">{children}</Box>,
    },
  } as Partial<PortableTextReactComponents>;

  return (
    <Box
      w="100%"
      mb={defaultStyles.mbSection}
      display={revertOrder ? {base:'block', md:'flex'} : 'block'}
    >
      {image && (
        <Flex
          flexWrap={'wrap'}
          w={{base:'100%', md:'50%', xl:'581px'}}
          maxH={!image ? 'auto' : {base:'458px'}}
          position="relative"
          justifyContent="center"
          mb={{base:'20px', md:'0'}}
          float={revertOrder ? 'left' : 'right'}
          pr={revertOrder ? {base:'0', md:'38px'} : '0'}
          pl={revertOrder ? '0' : {base:'0', md:'38px'}}
        >
          {/* IMG WITHOUT LINK */}
          {!withLink && (
            <Flex
              maxW="100%"
              h="100%"
              position="relative"
            >
              <GatsbyImage image={image.asset.gatsbyImageData} alt={'wana blog image'} />
            </Flex>
          )}
          {/* IMG WITH LINK */}
          {withLink && (
            <LinkWrapper href={imgLink}>
              <Flex
                maxW="100%"
                h="100%"
                position="relative"
              >
                <GatsbyImage image={image.asset.gatsbyImageData} alt={'wana blog image'} /> 
              </Flex>
            </LinkWrapper>
          )}
        </Flex>
      )}

      <Box w={image && {base:'100%', md:'50%', xl:'50%'}} className={extra ? `blog-raw-content-cta` : 'blog-raw-content' } style={{color: handleColor()}}>
        <PortableText value={_rawOneColumnText} components={components}/>
      </Box>
    </Box>
  );
}

export default OneColumnText;