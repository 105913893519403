import type { 
  FlexOptions, 
  LayoutProps, 
  SpaceProps, 
  PositionProps, 
  FlexboxProps 
} from "@chakra-ui/react";

export const wrapperStyles = {
  direction: 'column' as FlexOptions['direction'],
  maxW: {
    base:'87%', ms:'87%', msx:'87%', md:'87%', lg:'84%', xl:'1200px'
  } as LayoutProps['maxW'],
  mx: 'auto' as SpaceProps['mx'],
  zIndex: '1' as PositionProps['zIndex'],
  alignItems: 'center' as FlexboxProps['alignItems'],
};

export const defaultStyles = {
  fontColor: 'primary',
  fontSizes: {
    'small': {base:'16px', md:'16px'},
    'normal': {base:'18px', md:'18px'},
    'h4': {base:'20px', md:'20px'},
    'h3': {base:'22px', md:'22px'},
    'h2': {base:'24px', md:'24px'},
    'h1': {base:'26px', md:'26px'},
  },
  fontWeights: {
    'normal': '400',
    'strong': '600',
  },
  lineHeights: {
    'small': {base:'28px', md:'28px'},
    'normal': {base:'30px', md:'30px'},
    'h4': {base:'32px', md:'32px'},
    'h3': {base:'34px', md:'34px'},
    'h2': {base:'36px', md:'36px'},
    'h1': {base:'38px', md:'38px'},
  },
  letterSpacings: {
    'small': {base:'0px', md:'0px'},
    'normal': {base:'0px', md:'0px'},
    'h4': {base:'0px', md:'0px'},
    'h3': {base:'0px', md:'0px'},
    'h2': {base:'0px', md:'0px'},
    'h1': {base:'0px', md:'opx'},
  },
  textAlign: {base:'left', md:'left'},
  mbSection: {base:'20px', md:'15px'},
  mbContainers: {base:'20px', md:'20px'},
  hover: {borderColor:'primary'},
  linkFontStyle: 'italic',
  listsLeftMargin: {base:'30px', md:'60px'},
  listsRightMargin: {base:'20px', mds:'10px'},
  listItemBottomMargin: {base:'10px', mds:'10px'},
};

export const commonStyles = {
  mb: {
    base:'20px', 
    mds:'40px',
  },
};
