import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import CTAButton from '../../components/CTAButton';

type ShopWanaOnlineProps = {
  data: any;
};

function ShopWanaOnline({ data }: ShopWanaOnlineProps) {

  return (
    <Flex
      key={data._key}
      w="100%"
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ zIndex: 1 }}
    >
      {/* CONTENT */}
      <Flex
        w="100%"
        direction="column"
        alignItems="center"
        mt={{ base: "114px", md: "114px" }}
        style={{ zIndex: 1 }}
      >
        {/* TITLE */}
        <Text
          color="primary"
          fontSize={{
            base: "44px",
            ms: "47px",
            msx: "50px",
            md: "53px",
            mds: "56px",
            lg: "59px",
            lgs: "62px",
            xl: "65px",
          }}
          fontWeight={{ base: "900", md: "900" }}
          letterSpacing={{ base: "-0.5px", md: "-0.5px" }}
          lineHeight={{ base: "1" }}
          textAlign="center"
          textTransform="uppercase"
          mb={{ base: "26px", md: "26px" }}
        >
          {data.header || "shop wana online"}
        </Text>

        {/* SUBTITLE */}
        <Text
          w={{ base: "70vw", ms: "365px", msx: "370px" }}
          color="primary"
          fontSize={{ base: "12px", md: "15px" }}
          fontWeight={{ base: "600", md: "600" }}
          letterSpacing={{ base: "0px", md: "0px" }}
          lineHeight={{ base: "24px", md: "30px" }}
          textAlign="center"
          mb={{ base: "12px", md: "29px" }}
        >
          Search a product below to find out where you can pre-order
          for pick-up. Prices may vary by dispensary.
          <br />
          Don't need to pre-order?
        </Text>

        {/* FIND BTN */}
        <CTAButton
          href={data.findBtn.ctaRoute.route || "/"}
          width={{ base: "270px", md: "316px" }}
          height={{ base: "48px", lg: "48px" }}
          fontSize={{ base: "13px", md: "13px" }}
          fontWeight="900"
          bgColor={
            data.findBtn.bgColor
              ? data.findBtn.bgColor.color
              : "greenMantis"
          }
          color={
            data.findBtn.textColor
              ? data.findBtn.textColor.color
              : "white"
          }
          _hover={{ bg: "#311D56", borderColor: "#311D56" }}
          ctaText={data.findBtn.text}
        />
      </Flex>
    </Flex>
  );
}

export default ShopWanaOnline;
