import * as React from "react";
import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import SimpleCta from "../LandingPageSections/SimpleCta";
import { PortableText } from "@portabletext/react";

function HempHero(props) {
  const { heroTitle, bgImage, heroCta, _rawHeroText,_rawSecondHeroText } = props;

  const [firstPart, secondPart, thirdPart, fourthPart] = heroTitle.split(" ");
  const oneColumnTextStyles = {
    fontColor: "#FFFFFF",
    fontSizes: {
      normal: { base: "16px", lg: "32px" },
    },
    fontWeights: {
      normal: "600",
      strong: "600",
    },
    lineHeights: {
      normal: { base: "30px", lg: "60px" },
    },
    letterSpacings: {
      normal: { base: "0px" },
    },
    mbSection: { base: "0px", md: "0px" },
    mbContainers: { base: "0", md: "0" },
    linkFontStyle: "italic",
    listsLeftMargin: { base: "30px", md: "60px" },
  };

  const handleForm = () => {
    window._klOnsite = window._klOnsite || [];
    window._klOnsite.push(["openForm", "UdkFKJ"]);
  };

  const components = {
    marks: {
      link: ({ value, children }) => {
        const target = (value?.href || "").startsWith("http") ? "_blank" : undefined;
        return (
          <a
            href={value?.href}
            target={target}
            rel={target === "_blank" ? "noindex nofollow" : ""}
            style={{
              color: oneColumnTextStyles.fontColor,
              fontStyle: oneColumnTextStyles.linkFontStyle,
              textDecoration: "underline",
            }}
          >
            {children}
          </a>
        );
      },
      em: ({ children }) => <span style={{ fontStyle: "italic" }}>{children}</span>,
    },
    listItem: {
      bullet: ({ children }) => (
        <li
          style={{
            listStylePosition: "inside",
            marginLeft: oneColumnTextStyles.listsLeftMargin.base,
          }}
        >
          {children}
        </li>
      ),
      number: ({ children }) => <li>{children}</li>,
      left: ({ children }) => <Box textAlign="left">{children}</Box>,
      center: ({ children }) => <Box textAlign="center">{children}</Box>,
      right: ({ children }) => <Box textAlign="right">{children}</Box>,
    },
    block: {
      normal: ({ children }) => (
        <Box
          as="p"
          sx={{
            color: oneColumnTextStyles.fontColor,
            fontSize: oneColumnTextStyles.fontSizes.normal,
            fontWeight: oneColumnTextStyles.fontWeights.normal,
            lineHeight: oneColumnTextStyles.lineHeights.normal,
            mb: oneColumnTextStyles.mbContainers.base,
            fontFamily: "Neutra Text Demi Alt",
            textAlign: "center",
          }}
        >
          {children}
        </Box>
      ),
    },
  } as Partial<any>;
  return (
    <Flex
      maxW="100%"
      justifyContent="center"
      position="relative"
      id="hero-section"
      className={"hero-hemp-bg"}
      mb="25px"
      mt={-20}
      height={"100%"}
      minH={"100vh"}
      alignItems={"center"}
   
    >
      {/* TEXT CONTENT */}
      <Box
        w="100%"
        h="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop={"80px"}
        marginBottom={"80px"}
      >
        <Heading
          as="span"
          color={"white"}
          fontFamily="Neutraface"
          fontSize={{
            base: "56px", // Adjusted proportionally
            msx: "60px",
            md: "66px",
            lg: "70px",
            lgs: "80px",
            xl: "98px", // Keeping this as is
          }}
          fontWeight="900"
          lineHeight={{
            base: "56px",
            msx: "60px",
            md: "66px",
            lg: "70px",
            lgs: "80px",
            xl: "98px",
          }}
          textAlign={"center"}
          letterSpacing={{ base: "0.55px", md: "1.08px" }}
        >
          {firstPart} {secondPart}
        </Heading>
        <Heading
          as="span"
          color={"white"}
          fontFamily="Neutraface"
          fontSize={{
            base: "70px", // Adjusted proportionally
            msx: "80px",
            md: "90px",
            lg: "100px",
            lgs: "120px",
            xl: "135px", // Keeping this as is
          }}
          fontWeight="900"
          lineHeight={{
            base: "70px",
            msx: "80px",
            md: "90px",
            lg: "100px",
            lgs: "120px",
            xl: "135px",
          }}
          letterSpacing={{ base: "0.55px", md: "1.08px" }}
        >
          {thirdPart} {fourthPart}
        </Heading>
        <Image
          width={{base:'600px',lg:'700px'}}
          marginTop={{ base: "0px", lg: "0px" }}
          padding={{base:'20px',lg:'0px'}}
          src={bgImage.asset.url}
          alt={"Hemp Hero"}
        />
        {/* HERO CTA */}
      
        <Box
          w="100%"
          h="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginTop={ {base:'40px',lg:'40px'}}
          marginBottom={ {base:'40px',lg:'40px'}}
          maxW={"1440px"}
          padding={"0 20px"}
         
        >
          <PortableText value={_rawHeroText} components={components} />
        </Box>
        {heroCta && (
          <SimpleCta
            text={heroCta.textContent}
            link={heroCta.externalLink || heroCta.internallLink?.route || "/"}
            color={heroCta.textColor.color}
            bgColor={heroCta.bgColor.color}
            inHero
          />
        )}
           <Box
          w="100%"
          h="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginTop={ {base:'40px',lg:'40px'}}
          maxW={"1440px"}
          padding={"0 20px"}
         
        >
          <PortableText value={_rawSecondHeroText} components={components} />
        </Box>
      </Box>
    </Flex>
  );
}

export default HempHero;
