import React, { useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import { Box } from "@chakra-ui/react";

import SeoComponent from "../components/SeoComponent";
import Loading from "../components/Loading";
import SectionsPageSelector from "../sections/SectionsPageSelector";
import HeroStyleBlog from "../sections/generic/hero-style-blog";
import TwoCol from "../sections/generic/two-col";

type GenericPageProps = {
  data: {
    page: {
      seoTitle?: string;
      seoDescription?: string;
      seoImage?: any;
      slug: string;
      sections: any[];
    };
    events:any
  };
};

function GenericPage(props: GenericPageProps) {
  console.log(props.data)
  const { page } = props.data ?? { page: {} };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!page?.slug) return;

    setLoading(false);
  }, []);

  const renderLoading = () => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minH={{ base: 200, md: "calc(100vh - 480px)" }}
    >
      <Loading />
    </Box>
  );

  if (loading) {
    return renderLoading();
  }

  // if (!(page?.sections?.length > 0)) {
  //   navigate('/404');
  //   return renderLoading();
  // }
  return (
    <Box>
      <SeoComponent
        title={page?.seoTitle}
        description={page?.seoDescription}
        imageUrl={page?.seoImage?.image?.asset?.url}
      />
      <Box mt="20" />
      {page.sections.map((oneSection, idx) => (
        <SectionsPageSelector
          key={`one-section-${oneSection?._key}-${idx}`}
          data={oneSection}
          events={props?.data?.allSanityEvent?.edges}
        />
      ))}
    </Box>
  );
}

export const genericPageQuery = graphql`
  query GENERIC_PAGE_QUERY($id: String! , $isEventPage: Boolean!) {
    page: sanityPage(id: { eq: $id }) {
      id
      _id
      name
      slug {
        current
      }
      seoTitle
      seoDescription
      seoImage {
        image {
          asset {
            url
          }
        }
      }
      sections {
        ...AboutDetailsFragment
        ...CommunityDetailsFragment
        ...FoundationDetailsFragment
        ...FeaturedProductsHomeFragment
        ...FindWanaHomeFragment
        ...HeroFragment
        ...EventListFragment
        ...EventHeroFragment
        ...HeroHomeFragment
        ...ProductCategoriesHomeFragment
        ...ShopWanaOnlineFragment
        ...VideoHomeFragment
        ...WanaSupporterOfFragment
        ...TheWanaWayFragment
        ...ShopByCategoryFragment
        ...ShopcbdFragment
        ...WanaLearnFragment
        ...PodcastOptionsFragment
        ...PodcastGuestsFragment
        ...WanabrandFragment
        ...GenericColumnSectionFragment
        ...HempHeroFragment
        ...FaqsFragment
        ...GenericTwoColFragment
        ...GenericOneColumnText
        ...GenericBlogHeroFragment
        ...GenericBlogPostCtaFragment
        ...GenericImageFragment
      }
    }
    allSanityEvent @include(if: $isEventPage) {
      edges {
        node {
          _id
          name
          description
          date
          city
          state {
            name
            slug {
              current
            }
          }
          location
          time
          link
        }
      }
    }
  }
`;

export default GenericPage;
