import * as React from 'react';
import { Box, Text, Link, Flex, Image } from '@chakra-ui/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { AiFillPlayCircle } from 'react-icons/ai';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import CTAButton from '../../components/CTAButton';

const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    padding: 0,
    border: 'none',
    borderRadius: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#___gatsby');

function VideoHome(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { header, description, videoURL, coverImage, cta, linkToContent, parallaxImgs } = props;
  const cover = getImage(coverImage.asset);
  // console.log('VideoHome: ', props);
  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = 'hidden';
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = 'auto';
  }

  return (
    <>
      {/* VIDEO SECTION */}
      <Box
        w="100%"
        h="auto"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        bgColor="grayBG"
        mt={{base:'172px', ms:'192px', md:'182px', lg:'192px', xl:'250px'}}
        pb={{base:'105px', ms:'120px', msx:'140px', md:'105px', lg:'124px', xl:'138px'}}
      >
        {/* SVG TOP IMAGE */}
        <SvgCurve top />

        {/* CONTENT */}
        <ParallaxProvider>
          <Flex
            w={{base:'83%', md:'87%', lg:'84%', xl:'1200px'}} 
            h="auto"
            justifyContent={{base:'center', md:'flex-start'}}
            flexDirection={{base:'column', md:'row'}}
            position="relative"
            mx="auto"
          >
            {/* MAIN IMAGE */}
            <Flex
              maxW={{base:'100%', md:'61.7vw', mds:'61.7vw', lg:'61.7vw', lgs:'61.58vw', xl:'892px'}}
              maxH={{ms:'652px', mds:'72.85vw', lg:'63.67vw', lgs:'52.97vw', xl:'652px'}}
              mb={{base:'80px', md:'0'}}
              position="relative"
            >
              <GatsbyImage image={cover} alt={coverImage.asset?.altText || 'wana wont melt video'} />

              {/* PLAY VIDEO */}
              <Box
                w="100%"
                h="100%"
                position="absolute"
                top="0"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {/* ICON */}
                <Box
                  w={{base:'64px', ms:'67px', md:'7.5vw', mds:'7.82vw', lg:'7.32vw', lgs:'6.74vw', xl:'92px'}}
                  h={{base:'64px', ms:'67px', md:'7.5vw', mds:'7.82vw', lg:'7.32vw', lgs:'6.74vw', xl:'92px'}}
                  borderRadius="full"
                  cursor='pointer'
                  style={{zIndex:2}}
                  onClick={openModal}
                >
                  <AiFillPlayCircle style={{width:'100%', height:'100%', color:'#272158'}} />
                </Box>

                {/* ICON WHITE BACKGROUND */}
                <Box
                  w="46px"
                  h="46px"
                  bgColor="white"
                  borderRadius="full"
                  position="absolute"
                />
              </Box>
            </Flex>

            {/* TEXT CONTENT */}
            <Flex
              position={{base:'relative', md:'absolute'}}
              top={{md:'3.5vw', mds:'3vw', lg:'8.5vw', lgs:'9.75vw', xl:'149px'}}
              right={{md:'0'}}
              direction={{base:'column'}}
              alignItems={{base:'center', md:'flex-start'}}
              zIndex="2"
            >
              {/* HEADER */}
              <Text
                maxW={{base:'64.4vw', md:'28vw', mds:'27vw', lg:'26.2vw', lgs:'29.5vw', xl:'377px'}}
                as='h2'
                color={header.textColor?.color || 'primary'}
                fontSize={{base:'40px', md:'42px', mds:'55px', lg:'55px', lgs:'65px'}}
                fontWeight='800'
                lineHeight={{base:'45px', md:'42px', mds:'55px', lg:'55px', lgs:'65px'}}
                letterSpacing={{base:'-0.31px', md:'-0.5px'}}
                textAlign={{base:'center', md:'left'}}
                // textTransform='capitalize'
                pb={{base:'22px', md:'0', mds:'1vw', lg:'1vw', lgs:'0.95vw', xl:'12px'}}
                mb={{md:'1.3vw', mds:'1.5vw', lg:'2.44vw', lgs:'2.44vw', xl:'35px'}}
                // border="1px"
              >
                {header.headerText}
              </Text> 

              {/* DESCRIPTION */}
              <Text 
                maxW={{base:'69.72vw', md:'28vw', mds:'27vw', lg:'24.12vw', lgs:'20.1vw', xl:'274px'}}
                as='p' 
                color={header.textColor?.color || 'primary'}
                fontSize={{base:'15px', md:'17px'}}
                lineHeight={{base:'25px', md:'30px'}}
                letterSpacing={{base:'0'}}
                textAlign={{base:'center', md:'left'}}
                pb={{base:'14px', md:'0'}}
                mb={{base:'17px', md:'1.3vw', mds:'1.5vw', lg:'2.44vw', lgs:'3.57vw', xl:'52px'}}
                // border="1px"
              >
                {description}
              </Text>

              {/* LEARN MORE */}
              <Box>
                <CTAButton
                  href={`${cta.ctaRoute.route}${linkToContent.slug.current}`}
                  fontSize={{base:'13px'}}
                  bgColor={'red'}
                  color={'white'}
                  width={{base:'136px'}}
                  height={{base:'48px'}}
                  _hover={{bg:'blue'}}
                  ctaText={'learn more'}
                />
              </Box>
            </Flex>
            
            {/* LEFT PARALLAX IMAGE */}
            <Flex
              maxW={{base:'133px', md:'278px'}}
              maxH={{base:'210px', md:'278px'}}
              position="absolute"
              top={{base:'-19.43vw', ms:'-19vw', md:'-5.2vw', mds:'-5vw', lg:'-4.3vw', lgs:'-3.47vw', xl:'-50px'}}
              left={{base:'-80px', ms:'-80px', md:'-10.6vw', mds:'-10.7vw', lg:'-12.26vw', lgs:'-12.26vw', xl:'-180px'}}
              // border="1px"
            >
              <Parallax styleInner={{transition: `${parallaxImgs[0]?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[30, -20]} tagOuter='figure'>
                <Image 
                  src={parallaxImgs[0]?.image.asset.url}
                  boxSize={{base:'36.94vw', ms:'37vw', md:'20.29vw', mds:'20.29vw', lg:'20.29vw', lgs:'20.29vw', xl:'278px'}}
                  objectFit="contain"
                />
              </Parallax>
            </Flex>

            {/* RIGHT PARALLAX IMAGE */}
            <Flex
              maxW={{base:'78px', md:'128px'}}
              maxH={{base:'128px'}}
              position="absolute"
              top={{base:'70vw', ms:'60vw', md:'9vw', mds:'11vw', lg:'11vw', lgs:'12.5vw', xl:'180px'}}
              right={{base:'-10px', ms:'-10px', md:'-1.3vw', mds:'-1.3vw', lg:'-1.39vw', lgs:'-1.39vw', xl:'-20px'}}
              zIndex="1"
            >
              <Parallax styleInner={{transition: `${parallaxImgs[1]?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[30, -20]} tagOuter='figure'>
                <Image 
                  src={parallaxImgs[1]?.image.asset.url}
                  boxSize={{base:'21.67vw', ms:'21.67vw', md:'8.9vw', mds:'8.9vw', lg:'8.89vw', lgs:'8.89vw', xl:'128px'}}
                  objectFit="contain"
                />
              </Parallax>
            </Flex>

            {/* BOTTOM PARALLAX IMAGE */}
            <Flex
              maxW={{base:'155px', md:'233px'}}
              maxH={{base:'155px', md:'233px'}}
              position="absolute"
              bottom={{base:'-150px', ms:'-120px', msx:'-80px', md:'-11.5vw', mds:'-11vw', lg:'-10.5vw', lgs:'-10vw', xl:'-150px'}}
              left={{base:'14vw', ms:'23vw', md:'17vw', mds:'17vw', lg:'16.5vw', lgs:'17.36vw', xl:'260px'}}
              zIndex={{base:1}}
            >
              <Parallax styleInner={{transition: `${parallaxImgs[2]?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[-7, 32]}>
                <Image 
                  src={parallaxImgs[2]?.image.asset.url}
                  boxSize={{base:'43vw', ms:'43vw', md:'16.18vw', mds:'16.18vw', lg:'16.18vw', lgs:'16.18vw', xl:'233px'}}
                  objectFit="contain"
                />
              </Parallax>
            </Flex>

          </Flex>
        </ParallaxProvider>

        {/* SVG BOTTOM IMAGE */}
        <SvgCurve top={false} />
      </Box>

      {/* MODAL */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel='Example Modal'>
        <Box className='player-wrapper'>
          <ReactPlayer className='react-player' width='100%' height='100%' playing={modalIsOpen} auto url={videoURL} controls={true} />
        </Box>
      </Modal>
    </>
  );
}

const SvgCurve = ({ top }) => {
  const position = top ? {top:'0'} : {bottom:'0'};

  return (
    <Box
      w="100%"
      h={top ? {base:'10%', md:'10%', mds:'15%', lg:'30%', lgs:'30%', xl:'30%'} : {base:'5%', md:'10%'}}
      position="absolute"
      left="0"
      style={{zIndex:0, ...position}}
    >
      {/* TOP SVG */}
      {top && (
        <svg 
          viewBox="0 0 500 149" 
          preserveAspectRatio="none" 
          style={{width:'100%', height:'100%'}}
        >
          <path
            fill="white"
            fillOpacity='1'
            // d="M-0.27,79.45 C213.60,4.47 306.15,1.50 501.41,80.44 L500.00,0.00 L0.00,0.00 Z"
            d="M-35.26,82.41 C213.60,4.47 306.15,1.50 531.88,97.22 L500.00,0.00 L0.00,0.00 Z"
          >
          </path>
        </svg> 
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            fillOpacity='1'
            d="M0.00,49.98 C149.99,150.00 330.98,145.56 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          >
          </path>
        </svg> 
      )}
    </Box>
  );
};

export default VideoHome;
