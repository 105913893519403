import * as React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
  Flex,
  Box,
} from "@chakra-ui/react";
import { PortableText } from "@portabletext/react";
import SimpleCta from "../LandingPageSections/SimpleCta";
import faqArrow from "../../images/faq-arrow.png";

const oneColumnTextStyles = {
  fontColor: "#281F5C",
  fontSizes: {
    normal: { base: "16px", lg: "32px" },
  },
  fontWeights: {
    normal: "600",
    strong: "600",
  },
  lineHeights: {
    normal: { base: "30px", lg: "60px" },
  },
  letterSpacings: {
    normal: { base: "0px" },
  },
  mbSection: { base: "0px", md: "0px" },
  mbContainers: { base: "20px", md: "20px" },
  linkFontStyle: "italic",
  listsLeftMargin: { base: "30px", md: "60px" },
};

export const components = {
  marks: {
    link: ({ value, children }) => {
      const target = (value?.href || "").startsWith("http") ? "_blank" : undefined;
      return (
        <a
          href={value?.href}
          target={target}
          rel={target === "_blank" ? "noindex nofollow" : ""}
          style={{
            color: oneColumnTextStyles.fontColor,
            fontStyle: oneColumnTextStyles.linkFontStyle,
            textDecoration: "underline",
          }}
        >
          {children}
        </a>
      );
    },
    em: ({ children }) => <span style={{ fontStyle: "italic" }}>{children}</span>,
  },
  listItem: {
    bullet: ({ children }) => (
      <li
        style={{
          listStylePosition: "inside",
          marginLeft: oneColumnTextStyles.listsLeftMargin.base,
        }}
      >
        {children}
      </li>
    ),
    number: ({ children }) => <li>{children}</li>,
    left: ({ children }) => <Box textAlign="left">{children}</Box>,
    center: ({ children }) => <Box textAlign="center">{children}</Box>,
    right: ({ children }) => <Box textAlign="right">{children}</Box>,
  },
  block: {
    normal: ({ children }) => (
      <Box
        as="p"
        sx={{
          color: oneColumnTextStyles.fontColor,
          fontSize: oneColumnTextStyles.fontSizes.normal,
          fontWeight: oneColumnTextStyles.fontWeights.normal,
          lineHeight: oneColumnTextStyles.lineHeights.normal,
          mb: oneColumnTextStyles.mbContainers.base,
          fontFamily: "Neutra Text Demi Alt",
        }}
      >
        {children}
      </Box>
    ),
  },
} as Partial<any>;

function Faqs({ faqs,_rawOneColumnText, _rawFrontOneColumnText }) {
  const [items, setItems] = React.useState([0]);

  const handleAccordionItem = (index) => {
    setItems((prevItems) =>
      prevItems.includes(index)
        ? prevItems.filter((item) => item !== index)
        : [...prevItems, index]
    );
  };
  const handleForm = () => {
    window._klOnsite = window._klOnsite || [];
    window._klOnsite.push(["openForm", "UdkFKJ"]);
  };
  return (
    <Flex
      maxW="1440px"
      position="relative"
      id="faqs-section"
      mb="25px"
      mt="-20px"
      overflow="hidden"
      py="90px"
      px={"20px"}
      mx="auto"
      flexDirection={"column"}
      w={{ base: "100%", lg: "87%" }}
    >

      <Accordion allowToggle index={[0, ...items]} allowMultiple>
        {faqs.slice(0,1).map((faq, index) => (
          <AccordionItem
            marginBottom={{ base: "20px", lg: "20px" }}
            border={0}
            key={index}
          >
            <AccordionButton
              sx={{
                color: oneColumnTextStyles.fontColor,
                fontSize: oneColumnTextStyles.fontSizes.normal,
                fontWeight: oneColumnTextStyles.fontWeights.normal,
                lineHeight: oneColumnTextStyles.lineHeights.normal,
                fontFamily: "Neutraface",
                display: "flex",
                outline: "none",
                padding: "0",
                boxShadow: "none",
                alignItems: "start",
                justifyContent: "space-between",
                _focus: { boxShadow: "none" },
                _hover: {},
              }}
              onClick={() => handleAccordionItem(index)}
            >
              <Box
                textAlign="left"
                textDecoration="underline"
                textUnderlineOffset="8px"
              >
                {faq.faqTitle}
                {faq.collapsable && (
                <Box
                  backgroundColor="#281F5C"
                  padding={"6px 18px"}
                  borderRadius={"8px"}
                  margin={"auto 0 auto 8px"}
                  maxW={{ base: "40px", lg: "75px" }}
                  width={"100%"}
                  display={{ base: "none", lg: "inline-flex" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    src={faqArrow}
                    minW={{ base: "19px", lg: "38px" }}
                    objectFit="contain"
                  />
                </Box>
              )}
              </Box>
              {faq.collapsable && (
                <Box
                  backgroundColor="#281F5C"
                  padding={"6px 18px"}

                  borderRadius={"8px"}
                  margin={"auto 0 auto 8px"}
                  maxW={{ base: "40px", lg: "75px" }}
                  width={"100%"}
                  display={{ base: "flex", lg: "none" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    src={faqArrow}
                    minW={{ base: "19px", lg: "38px" }}
                    objectFit="contain"
                  />
                </Box>
              )}
            </AccordionButton>
            <AccordionPanel
              pb={4}
              padding={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
            >
              <PortableText value={faq._rawFaqText} components={components} />
              {faq.faqCta && (
                <SimpleCta
                  text={faq.faqCta.textContent}
                  link={
                    faq.faqCta.externalLink || faq.faqCta.internallLink?.route || "/"
                  }
                  color={faq.faqCta.textColor.color}
                  bgColor={faq.faqCta.bgColor.color}
                  inHero
                />
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <PortableText value={_rawFrontOneColumnText} components={components} />
      <Accordion allowToggle allowMultiple>
        {faqs.slice(1,faqs.length).map((faq, index) => (
          <AccordionItem
            marginBottom={{ base: "20px", lg: "20px" }}
            border={0}
            key={index}
          >
            <AccordionButton
              sx={{
                color: oneColumnTextStyles.fontColor,
                fontSize: oneColumnTextStyles.fontSizes.normal,
                fontWeight: oneColumnTextStyles.fontWeights.normal,
                lineHeight: oneColumnTextStyles.lineHeights.normal,
                fontFamily: "Neutraface",
                display: "flex",
                outline: "none",
                padding: "0",
                boxShadow: "none",
                alignItems: "start",
                justifyContent: "space-between",
                _focus: { boxShadow: "none" },
                _hover: {},
              }}
              onClick={() => handleAccordionItem(index)}
            >
              <Box
                textAlign="left"
                textDecoration="underline"
                textUnderlineOffset="8px"
              >
                {faq.faqTitle}
                {faq.collapsable && (
                <Box
                  backgroundColor="#281F5C"
                  padding={"6px 18px"}
                  borderRadius={"8px"}
                  margin={"auto 0 auto 8px"}
                  maxW={{ base: "40px", lg: "75px" }}
                  width={"100%"}
                  display={{ base: "none", lg: "inline-flex" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    src={faqArrow}
                    minW={{ base: "19px", lg: "38px" }}
                    objectFit="contain"
                  />
                </Box>
              )}
              </Box>
              {faq.collapsable && (
                <Box
                  backgroundColor="#281F5C"
                  padding={"6px 18px"}

                  borderRadius={"8px"}
                  margin={"auto 0 auto 8px"}
                  maxW={{ base: "40px", lg: "75px" }}
                  width={"100%"}
                  display={{ base: "flex", lg: "none" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    src={faqArrow}
                    minW={{ base: "19px", lg: "38px" }}
                    objectFit="contain"
                  />
                </Box>
              )}
            </AccordionButton>
            <AccordionPanel
              pb={4}
              padding={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
            >
              <PortableText value={faq._rawFaqText} components={components} />
              {faq.faqCta && (
                <SimpleCta
                  text={faq.faqCta.textContent}
                  link={
                    faq.faqCta.externalLink || faq.faqCta.internallLink?.route || "/"
                  }
                  color={faq.faqCta.textColor.color}
                  bgColor={faq.faqCta.bgColor.color}
                  inHero
                />
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Box marginTop={{base:'40px',md:'60px'}}>
      <PortableText value={_rawOneColumnText} components={components} />
      </Box>
      <SimpleCta
        onClick={() => handleForm()}
        text={"Subscribe"}
        color={"#ffffff"}
        bgColor={"#281F5C"}
        isButton={true}
      />
    </Flex>
  );
}

export default Faqs;
