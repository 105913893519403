import * as React from "react";
import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { AiFillPlayCircle } from "react-icons/ai";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import { ParallaxProvider } from "react-scroll-parallax";
import CTAButton from "../../../components/CTAButton";
import OneColumnText from "../../BlogPostSections/OneColumnText";
import PostCTA from "../../BlogPostSections/PostCTA";
import ButtonCTA from "../cta/button-cta";

/* MODAL SETUP */
const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    padding: 0,
    border: "none",
    borderRadius: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#___gatsby");

/* RICH TEXT STYLES */
const oneColumnTextStyles = {
  fontSizes: {
    normal: { base: "17px", md: "17px", lg: "20px" },
    h4: { base: "22px", md: "19px", lg: "22px" },
    h3: { base: "24px", md: "21px", lg: "24px" },
    h2: { base: "26px", md: "23px", lg: "26px" },
    h1: { base: "28px", md: "25px", lg: "28px" },
  },
  fontWeights: {
    normal: "400",
    strong: "600",
  },
  lineHeights: {
    normal: { base: "1.5", md: "31px" },
    h4: { base: "1.5", md: "33px" },
    h3: { base: "1.5", md: "35px" },
    h2: { base: "1.5", md: "37px" },
    h1: { base: "1.5", md: "39px" },
  },
  letterSpacings: {
    normal: { base: "0px", md: "0px" },
    h4: { base: "0px", md: "0px" },
    h3: { base: "0px", md: "0px" },
    h2: { base: "0px", md: "0px" },
    h1: { base: "0px", md: "0px" },
  },
  textAlign: { base: "left", md: "left" },
  mbSection: { base: "0px", md: "0px" },
  mbContainers: { base: "20px", md: "20px" },
  hover: { borderColor: "primary" },
  linkFontStyle: "italic",
  listsLeftMargin: { base: "30px", md: "60px" },
  style: { wordSpacing: "2px" },
};

function TwoCol(props) {
  const sectionData = props;

  /* MODAL CONTROL */
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState(null);

  const openModal = () => {
    setVideoURL(sectionData.videoURL);
    setModalIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      {/* VIDEO SECTION */}
      <Box
        w="100%"
        h="auto"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        fontFamily="Outfit"
        p={{ base: "20px 0", md: "20px 0" }}
      >
        {/* CONTENT */}
        <ParallaxProvider>
          <Flex
            w={{ base: "87%", md: "87%", mds: "84%", lg: "84%", xl: "1200px" }}
            mx="auto"
            gap={{ base: "20px", md: "0" }}
            direction={{
              base: props.direction === "leftImage" ? "column" : "column",
              md: props.direction === "leftImage" ? "row-reverse" : "row",
            }}
          >
            {/* TEXT */}
            <Flex
              w={{ base: "100%", md: "49%" }}
              m={{
                base: "0",
                md: props.direction === "leftImage" ? "0 0 0 3%" : "0 3% 0 0",
              }}
              justifyContent="center"
              direction={{ base: "column", md: "column" }}
              position="relative"
            >
              <Heading
                color="primary"
                fontFamily="Outfit"
                fontSize={{
                  base: "30px",
                  md: "30px",
                  mds: "35px",
                  lg: "35px",
                  lgs: "40px",
                }}
                fontWeight="bold"
                lineHeight={{
                  base: "30px",
                  md: "30px",
                  mds: "35px",
                  lg: "35px",
                  lgs: "40px",
                }}
                letterSpacing={{ base: "-0.5px", md: "-0.5px" }}
                // textTransform="capitalize"
                pb={{ base: "10px", md: "17px" }}
                mb={{ base: "10px", md: "10px" }}
                textAlign={{ base: "left", md: "left" }}
              >
                {sectionData.sectionTitle}
              </Heading>

              <Box pl={{ base: "0", md: "0", mds: "0", lg: "0" }}>
                {sectionData._rawSectionText && (
                  <OneColumnText
                    _rawOneColumnText={sectionData._rawSectionText}
                    styles={oneColumnTextStyles}
                  />
                )}

                <Flex
                  justifyContent={{ base: "flex-start", md: "flex-start" }}
                  mb={{ base: "20px", md: "0" }}
                >
                  
                  {sectionData.cta && (
                    <ButtonCTA
                    text={sectionData.cta?._rawTextContent}
                    link={sectionData.cta?.externalLink || sectionData.cta?.internallLink?.route || "/"}
                    color={sectionData.cta?.textColor.color}
                    bgColor={sectionData.cta?.bgColor.color}
                    />
                  )}
                </Flex>
              </Box>
            </Flex>

            {/* VIDEO */}
            <Flex
              w={{ base: "100%", md: "48%" }}
              position="relative"
              justifyContent="center"
              alignItems="center"
            >
              <GatsbyImage
                image={sectionData.coverImage.asset.gatsbyImageData}
                alt={""}
              />

              {/* PLAY BUTTON IF MEDIA IS A VIDEO */}
              {sectionData.videoURL && (
                <Box
                  w="100%"
                  h={{ base: "100%" }}
                  position="absolute"
                  top="0"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* ICON */}
                  <Box
                    w="auto"
                    h="auto"
                    borderRadius="full"
                    cursor="pointer"
                    style={{ zIndex: 2 }}
                    onClick={openModal}
                  >
                    <AiFillPlayCircle
                      style={{ fontSize: "92px", color: "#272158" }}
                    />
                  </Box>

                  {/* ICON WHITE BACKGROUND */}
                  <Box
                    w="46px"
                    h="46px"
                    bgColor="white"
                    borderRadius="full"
                    position="absolute"
                  />
                </Box>
              )}
            </Flex>
          </Flex>
        </ParallaxProvider>
      </Box>

      {/* MODAL */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Box className="player-wrapper">
          <ReactPlayer
            className="react-player"
            width="100%"
            height="100%"
            playing={modalIsOpen}
            auto
            url={videoURL}
            controls={true}
          />
        </Box>
      </Modal>
    </>
  );
}

export default TwoCol;
