import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';

import LinkWrapper from '../../components/LinkWrapper';
import useWindowSize from '../../utitlities/useWindowSize';
import { commonStyles, wrapperStyles } from './generic-column-section-style';

function OneColumnImage(props) {
  const { oneColumnImage } = props;
  let image: any = null;
  let imageForMobile: any = null;
  let imgLink = '/';
  let withLink: any = null;
  const width: any = useWindowSize();

  if (oneColumnImage) {
    const { linkType, internallLink, externalLink } = oneColumnImage;

    image = oneColumnImage?.image;
    imgLink = linkType ? linkType === 'internallLink' ? internallLink.route : externalLink : '/';
    withLink = linkType ? true : false;
    imageForMobile = oneColumnImage?.imageForMobile;
  }

  return (
    <Flex {...wrapperStyles}>
      <Box
        w={{base:'95vw', md:'100%'}}
        h="auto"
        mb={commonStyles ? commonStyles.mb : {base:'65px', md:'65px'}}
      >
        {image && (
          <>
            {/* IMG WITHOUT LINK */}
            {!withLink && (
              <Flex
                maxW={{base:'100%', md:'100%'}}
                h="auto"
                position="relative"
                justifyContent="center"
                alignItems="center"
              >
              {
                width?.width < 768 ? (
                  <GatsbyImage 
                    image={imageForMobile?.asset?.gatsbyImageData}
                    alt={'wana subcategory image'}
                  />
                ) : (
                
                  <GatsbyImage 
                    image={image?.asset?.gatsbyImageData}
                    alt={'wana subcategory image'}
                  />
                )
              }
              </Flex>
            )}

            {/* IMG WITH LINK */}
            {withLink && (
              <LinkWrapper href={imgLink}>
                <Flex
                  maxW={{base:'100%', md:'100%'}}
                  h="auto"
                  position="relative"
                  justifyContent="center"
                  alignItems="center"
                >
                {
                  width.width < 768 ? (
                    <GatsbyImage 
                      image={imageForMobile?.asset?.gatsbyImageData}
                      alt={'wana subcategory image'}
                    />
                  ) : (
                  
                    <GatsbyImage 
                      image={image?.asset?.gatsbyImageData}
                      alt={'wana subcategory image'}
                    />
                  )
                }
                </Flex>
              </LinkWrapper>
            )}
          </>
        )}
      </Box>
    </Flex>
  );
}

export default OneColumnImage;
