import React from "react";
import { Box, Flex } from "@chakra-ui/react";

import NewAboutDetails from "../NewAboutDetails";
import CommunityDetails from "../CommunityDetails";
import FoundationDetails from "../FoundationDetails";
import FeaturedProductsHome from "../FeaturedProductsHome";
import ComboFindWanaHome from "../ComboFindWanaHome";
import Hero from "../Hero";
import HomeHero from "../HomeHero";
import ThreeCategoriesWideHome from "../ThreeCategoriesWideHome";
import ShopWanaOnline from "../ShopWanaOnline/ShopWanaOnline";
import VideoHome from "../VideoHome";
import WanaSupporterOf from "../WanaSupporterOf";
import TheWanaWay from "../TheWanaWay";
import ShopByCategory from "../ShopByCategory";
import ShopCBD from "../ShopCBD";
import WanaLearn from "../WanaLearn";
import PodcastOptions from "../PodcastOptions";
import PodcastGuests from "../PodcastGuests";
import WanaBrand from "../WanaBrand";
import OneColumnImage from "../GenericColumnSection/OneColumnImage";
import OneColumnText from "../GenericColumnSection/OneColumnText";
import TwoColumnsImgWrapperText from "../GenericColumnSection/TwoColumnsImgWrapperText";
import { wrapperStyles } from "../GenericColumnSection/generic-column-section-style";
import HempHero from "../hempHero";
import Faqs from "../faqs";
import TwoCol from "../generic/two-col";
import HeroStyleBlog from "../generic/hero-style-blog";
import PostCTA from "../BlogPostSections/PostCTA";
import { GatsbyImage } from "gatsby-plugin-image";
import EventHero from "../EventHero";
import EventList from "../EventList";

type SectionsPageSelectorProps = {
  data: {
    _key: string;
    _type: string;
    [x: string]: any;
  };
  events: any;
};

function SectionsPageSelector({ data, events }: SectionsPageSelectorProps,) {
  if (!data?._type) {
    return null;
  }
  try {
    switch (data._type) {
      case "aboutDetails": {
        return <NewAboutDetails data={data} />;
      }
      case "communityDetails": {
        return <CommunityDetails data={data} />;
      }
      case "foundationDetails": {
        return <FoundationDetails data={data} />;
      }
      case "featuredProductsHome": {
        return <FeaturedProductsHome {...(data as any)} />;
      }
      case "findWanaHome": {
        return <ComboFindWanaHome {...data} />;
      }
      case "hero": {
        return (
          <Hero
            header={data?.mainHeader.headerText}
            subheader={data?.accentHeader.headerText}
            heroBgImg={data?.heroImg}
          />
        );
      }
      case 'eventHero':
        return  (
          <EventHero
            header={data?.title}
            description={data?.description}
            heroBgImg={data?.heroImg}
          />
        );

      case 'eventList':
        return (
          <EventList events={events} {...data}/>
        )
      case "heroHome": {
        return <HomeHero {...data} />;
      }
      case "hempHero": {
        return <HempHero {...data} />;
      }
      case "faqs": {
        return <Faqs {...data} />;
      }
      case "productCategoriesHome": {
        return <ThreeCategoriesWideHome {...(data as any)} />;
      }
      case "shopWanaOnline": {
        return <ShopWanaOnline data={data} />;
      }
      case "videoHome": {
        return <VideoHome {...data} />;
      }
      case "wanaSupporterOf": {
        return <WanaSupporterOf data={data} />;
      }
      case "theWanaWay": {
        return <TheWanaWay data={data} />;
      }
      case "shopByCategory": {
        return <ShopByCategory data={data} />;
      }
      case "shopcbd": {
        return <ShopCBD data={data} />;
      }
      case "wanaLearn": {
        return <WanaLearn data={data} />;
      }
      case "podcastOptions": {
        return <PodcastOptions {...data} />;
      }
      case "podcastGuests": {
        return <PodcastGuests {...data} />;
      }
      case "wanabrand": {
        return <WanaBrand {...data} />;
      }
      case "genericColumnSection": {
        if (data?.sectionType === "oneColumnImage") {
          return <OneColumnImage {...data} />;
        } else if (data?.sectionType === "twoColumnsWrapperTextImage") {
          return <TwoColumnsImgWrapperText {...(data as any)} />;
        }
        return (
          <Flex {...wrapperStyles}>
            <OneColumnText {...data} />
          </Flex>
        );
      }
      case "genericTwoColumn":
        return <TwoCol {...data} />;
      case "genericOneColumnText":
        return (
          <Box p={{ base: "60px 0 0", md: "20px 0" }}>
            <Flex {...wrapperStyles}>
              <OneColumnText {...data} />{" "}
            </Flex>
          </Box>
        );
      case "genericBlogHero":
        return <HeroStyleBlog {...data} />;
      case "blogPostCTA":
        return (
          <Box mx={'auto'} maxW={{base:'93%'}} p={{ base: "20px 0 40px 0", md: "20px 0 40px 0" }}>
         
          <PostCTA
            text={data._rawTextContent}
            link={data.externalLink || data.internallLink?.route || "/"}
            color={data.textColor.color}
            bgColor={data.bgColor.color}
          />
       
          </Box>
        );
      case "genericImage":
        return (
          <Flex
            flexDirection="column"
            maxW={{ base: "100%", md: "100%" }}
            h="auto"
            position="relative"
            justifyContent="center"
            alignItems="center"
          >
            <GatsbyImage image={data.mainImage.asset.gatsbyImageData} alt="" />
          </Flex>
        );
      default: {
        return null;
      }
    }
  } catch (error) {
    return null;
  }
}

export default SectionsPageSelector;
